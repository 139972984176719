<template>

  <template v-if="store.oldSiteContract && currentContract()">


    <div v-if="currentContract().productType.id !== store.oldSiteContract.productType.id"
         class="ui warning message">
      <p>
        Ez egy <strong>{{ currentContract().productType.name }}</strong>
        típusú szerződés, a kiválasztott telephely pedig
        <strong>{{ store.oldSiteContract.productType.name }}</strong> típusú.
      </p>

      <p>Az átszerződés <strong>nem fog sikerülni!</strong></p>
      <p>Válassz másik telephelyet, vagy válassz másik szerződést. </p>
      <p>Ha nincs ilyen szerződés, nosza csinált egyet egyet!</p>

    </div>


    <div v-if="currentContract().id === store.oldSiteContract.id"
         class="ui warning message">
      <div class="header">Önmagára nem lehet átszerződni!</div>
      <p>Válassz másik telephelyet, vagy válassz másik szerződést. </p>
    </div>


    <div v-if="store.oldSiteContract.status !== 'active'"
         class="ui warning message">
      <div class="header">Csak aktív telephelyet lehet átszerelni ide! Még fel nem szerelt vagy már leszerelt telephelyet nem</div>
      <p>Válassz másik telephelyet, vagy válassz másik szerződést. </p>
    </div>






  </template>

  <h3>A régi telephely</h3>
  <div class="ui sixteen width grid">
    <div class="eight wide column">

      <template v-if="store.oldSite">
        <h3 class="ui blue header">
          {{ store.oldSite.nameAtContract }}
        </h3>
        <p>
          {{ store.oldSite.address.city }},
          {{ store.oldSite.address.street }}
        </p>
      </template>
    </div>

    <div class="eight wide column">

      <template v-if="store.oldSiteContract">

        <div class="ui middle aligned divided list">
          <condition-item>
            <template v-slot:name>Számlafizető:</template>
            <template v-slot:value>{{ store.oldSiteContract.customer.name }}</template>
          </condition-item>

          <condition-item>
            <template v-slot:name>Cime:</template>
            <template v-slot:value>
              {{ store.oldSiteContract.customer.mainAddress.postalCode }}
              {{ store.oldSiteContract.customer.mainAddress.city }}
              {{ store.oldSiteContract.customer.mainAddress.street }}
            </template>
          </condition-item>


          <condition-item>
            <template v-slot:name>Szolgáltató:</template>
            <template v-slot:value>
              {{ store.oldSiteContract.serviceCompany.name }}
            </template>
          </condition-item>


          <condition-item>
            <template v-slot:name>Terméktípus:</template>
            <template v-slot:value>
              {{ store.oldSiteContract.productType.name }}
            </template>
          </condition-item>

          <condition-item>
            <template v-slot:name>Szerződés száma:</template>
            <template v-slot:value>
              {{ store.oldSiteContract.registrationNumber }}
            </template>
          </condition-item>

        </div>
      </template>
    </div>

  </div>


</template>

<script setup>

//import { mapState } from 'vuex'
import {useStore} from 'vuex'

import {useReContractSiteStore} from "@/store-pinia/reContractSite";
import ConditionItem from "@/components/contract/create/checkOneCondition";

const store = useReContractSiteStore();

function currentContract() {
  const vuex_store = useStore();
  return vuex_store.state.updateContract.contract;
}


</script>

//computed:{
//calculation(){
//return this.$store.state.updateContract.calculationResult
//}
//}

<style scoped>

</style>