<template>

  <div id="main" class="ui container">
    <div class="ui grid">
      <div class="four wide column">
        <h2 class="ui header">Szerződés szerkesztése</h2>
      </div>


      <div class="twelve wide right aligned column ">
        <button class="ui basic green  button" @click="refreshContract(contract)">
          <i class="sync alternate icon"></i>
          Frissítés
        </button>


        <button v-if="transitionSites.length > 0 && contract.productType.id !== preferredProductTypeId  "
                class="ui basic teal  button" @click="onCreateNewContract">
          <i class="share square icon"></i>
          Átszerződés az új terméktípusra
        </button>

        <button class="ui teal button" @click="returnToCRM">Szerződések listájához</button>
      </div>
    </div>


    <error-notification/>

    <div class="ui segment contract-block">
      <contract-info/>
      <contract-conditions @click="onShowEditConditionsModal"/>
    </div>


    <div class="ui segment sites" style="margin-bottom: 50px">

      <div class="ui sixteen wide grid">
        <div class="eleven wide column">
          <h3 class="ui header">Telephelyek</h3>
        </div>


        <div class="five wide right aligned column">
          <button class="ui basic green button"
                  @click="showAddNewSiteModal(true)">
            <i class="plus green icon"></i>
            Új telephely hozzáadása
          </button>
        </div>
      </div>


      <div class="ui styled accordion" style="width: 100%; margin-top: 1rem">
        <div v-for="(site, index) in sites" :key="site.id">
          <accordion-title :site="site" :index="index" @openEditSiteModal="showEditSiteModal = true"/>
          <accordion-content :site="site" :index="index"/>
        </div>
      </div>


    </div>

    <modal-edit-site :show="showEditSiteModal" @onCloseClick="showEditSiteModal=false"/>
    <modal-edit-conditions :show="showEditConditionsModal" @onCloseClick="showEditConditionsModal=false"/>
    <modal-add-site-cost/>
    <modal-site-terminate-request/>
    <modal-cost-terminate-request/>
    <modal-re-contract-site/>
    <modal-price-list/>
    <modal-costs-detailing/>
    <modal-create-new-site/>


  </div>

</template>

<script>


import * as yup from "yup";

import ContractConditions from "@/components/contract/update/conditions";
import ContractInfo from "@/components/contract/update/contractInfo";
import {mapState} from "vuex";


import AccordionTitle from "@/components/contract/update/accordion-title";
import AccordionContent from "@/components/contract/update/accordion-content";


import ModalEditSite from "@/components/contract/update/modals/modal-edit-site.vue";
import ModalEditConditions from "@/components/contract/update/modals/modal-edit-conditions.vue";
import ModalAddSiteCost from "@/components/contract/update/modals/modal-add-site-cost.vue";
import ModalSiteTerminateRequest from "@/components/contract/update/modals/modal-site-terminate-request.vue";
import ModalCostTerminateRequest from "@/components/contract/update/modals/modal-cost-terminate-request.vue";
import ModalReContractSite from "@/components/contract/update/modals/modal-reContract-site.vue";
import ModalPriceList from "@/components/contract/update/modals/modal-priceList.vue";
import ModalCostsDetailing from "@/components/contract/update/modals/modal-costs-detailing.vue";
import ModalCreateNewSite from "@/components/contract/update/modals/modal-create-new-site.vue";


import {useReContractSiteStore} from "@/store-pinia/reContractSite";
import {useErrorsStore} from "@/store-pinia/errors";
import {onMounted} from "vue";
import {useRoute} from "vue-router";

import ErrorNotification from "@/components/ErrorComponent.vue";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";


export default {
  name: "ContractUpdatePage",

  setup() {
    const store_reContract = useReContractSiteStore();
    const store_errors = useErrorsStore();
    const store_priceList = useContractPriceListStore();
    onMounted(() => {
      store_reContract.fetchSites();
      const route = useRoute();
      store_priceList.fetchContractPriceList(route.params.contract_id);
    })

    return {
      store_reContract,
      store_errors
    }
  },


  data() {
    return {
      showEditSiteModal: false,
      showEditConditionsModal: false,
      schema: yup.object().shape(
          {
            name: yup.string().required('A telepítés helyét el kell nevezni!'),
            city: yup.string().required('A település nevét meg kell adni!'),
            street: yup.string().required('Megadása kötelező!'),
            costs: yup.array().of(
                yup.object().shape({
                      priceListItem: yup.string().required('Meg kell adni!'),
                      quantity: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                    }
                )
            )
          }
      ),
      initialValues: {
        name: '',
        city: '',
        street: '',
        costs: [
          {
            priceListItem: '',
            quantity: 1
          }
        ]
      },
    }

  },
  components: {
    ModalCostsDetailing,
    ModalPriceList,

    ModalReContractSite,
    ContractInfo,
    AccordionContent,
    AccordionTitle,
    ContractConditions,
    ModalEditSite,
    ModalEditConditions,
    ModalAddSiteCost,
    ModalSiteTerminateRequest,
    ModalCostTerminateRequest,
    ModalCreateNewSite,
    ErrorNotification,

  },
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,
      sites: state => state.updateContract.contract ? state.updateContract.contract.sites : [],
      transitionSites: state => state.updateContract.transitionSites,
      preferredProductTypeId: state => state.app.preferredProductTypeId,
    })

  },
  methods: {

    // onNewSiteSubmit(values) {
    //
    //   alert('Ezt hívon (ContractUpdatePage)' + JSON.stringify(values, null, 2));
    //
    //
    //   //this.$store.dispatch('updateContract/createSite', values);
    //   this.showNewSiteModal = false;
    // },

    onCreateNewContract() {
      this.$store.dispatch('updateContract/makeNewContractType')
    },

    returnToCRM() {
      window.location.href = this.$store.state.app.navigate.return
    },
    refreshContract(contract) {
      this.$store.dispatch('updateContract/fetchContract', contract.id)
    },

    onSendApproveRequest() {
      this.$router.push('/approve-request')
    },
    showAddNewSiteModal() {
      this.$store.commit('updateContract/setShowNewSiteModal', true)
    },
    onShowEditConditionsModal() {
      this.showEditConditionsModal = true;
    },

  },

  created() {
    this.$store.dispatch('updateContract/fetchContract', this.$route.params.contract_id)

  }

}
</script>


<style scoped>
.saveBtn {
  margin-top: 1rem;
}


</style>
