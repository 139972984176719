<template>
    <div v-if="store.errors.length"
         class="ui negative message">
        <i class="close icon" @click.stop="store.clearErrors"></i>
        <div class="header">
            Hoppá, hiba történt
        </div>

        <div class="ui list">
            <div v-for="(error, index) in store.errors" :key="index"
                 class="item">
                <div class="ui equal width grid">
                    <div class="column">
                        <i class="bug icon"></i>
                        {{ error.message_public }}
                    </div>
                    <div class="column">

                        <template v-if="error.code === 404">Nem található a kért adat</template>
                        <template v-if="error.code === 400">Hibás kérés</template>
                        <template v-if="error.code === 401">Ismeretlen felhasználó</template>
                        <template v-if="error.code === 403">Hozzáférés megtagadva</template>

                        <template v-if="error.code === 500">Belső (ORO) szerver hiba</template>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script setup>

import {useErrorsStore} from "@/store-pinia/errors";


const store = useErrorsStore();

</script>

<style scoped>

</style>
