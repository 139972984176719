<template>
    <div class="ui segment quotations" v-if="store_opportunity.opportunity">
        <div v-if="store_opportunity.opportunity.quotations.length > 0">
            <h4 class="ui header">Ezek az árajánlatok készültek</h4>
            <div class="ui five cards">
                <div class="card quotation" v-for="quotation in store_opportunity.opportunity.quotations"
                     :key="quotation.id"
                     @click="selectQuotation(quotation)">
                    <div class="content">
                        <div class="header">
                            {{ quotation.product.name }}
                        </div>
                        <div class="meta">
                            Készült: {{ quotation.dateOfQuotation }}
                        </div>
                        <div class="description">
                            {{ quotation.registrationNumber }}

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui info message" v-else>
            <div class="header">
                Ehhez az opportunity-hez nem készült árajánlat!
            </div>
        </div>
    </div>
</template>

<script setup>
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";

const store_opportunity = useOpportunitiesStore()

const store_page = usePageCreateContractStore()


function selectQuotation(quotation) {
    store_page.setQuotation(quotation);
    store_page.setProduct(quotation.product);
    store_page.setActiveStep(2)
}


</script>

<style scoped>
.active {
    background-image: linear-gradient(
            45deg,
            rgb(230, 255, 230),
            rgb(153, 253, 153));
}

.expired {
    background-image: linear-gradient(-45deg, rgb(248, 98, 98), rgb(255, 230, 230));
}

.quotation {
    cursor: pointer;
}

.quotations {
    background-color: lightcyan;
}


</style>
