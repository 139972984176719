import {defineStore} from "pinia";
import _ from "lodash"

import wsseInterceptor from "@/_helper/wsseIntercentor";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import {useCalculationStore} from "@/store-pinia/calculation";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";

import vuex_store from '@/store'


export const useCostsStore = defineStore({
    id: 'cost',
    state: () => {
        return {
            priceList: [],
            site: null,
            newCosts: [],
            sending: false
        }
    },
    actions: {

        setPriceList() {
            const store_priceList = useContractPriceListStore();
            this.priceList = store_priceList.priceList;
        },

        setSite(site) {
            this.site = site
        },

        setNewCosts(new_costs_arr) {
            this.newCosts = new_costs_arr
        },


        setCancelOperation() {
            // this.setSite(null);

            this.setNewCosts([]);
        },

        setSending(sending_value) {
            this.sending = sending_value
        },

        getPriceListItemById(price_list_arr, search_id) {
            return price_list_arr.find((el) => el.id === search_id)
        },


        calculate(values) {


            if (!values) {
                this.setNewCosts([])
                return;
            }

            const calculatedNewCosts = values.need_items.map(el => {

                const foundPriceListItem = this.getPriceListItemById(this.priceList, el.id)

                const lastFoundPrice = foundPriceListItem.priceChanges[foundPriceListItem.priceChanges.length - 1].price

                const lastPrice = parseInt(lastFoundPrice) * parseInt(el.quantity);

                const category_name = foundPriceListItem.categoryName;

                let priceOneTime = 0;
                let priceMonthly = 0;
                let unitPriceOneTime = 0;
                let unitPriceMonthly = 0;

                if (category_name === 'install_cost') {
                    unitPriceOneTime = lastFoundPrice
                    priceOneTime = lastPrice;

                }
                if (category_name === 'monthly_fee') {
                    unitPriceMonthly = lastFoundPrice
                    priceMonthly = lastPrice;
                }

                return {
                    id: el.id,
                    name: foundPriceListItem.name,
                    quantity: el.quantity,
                    unitPriceOneTime: unitPriceOneTime,
                    unitPriceMonthly: unitPriceMonthly,
                    priceOneTime: priceOneTime,
                    priceMonthly: priceMonthly,
                    category: foundPriceListItem.categoryName,
                }
            });


            this.setNewCosts(calculatedNewCosts)
        },


        //############################

        async callCreateNewCosts() {

            const store_oro = useOroStore();
            const store_calculation = useCalculationStore()
            const store_errors = useErrorsStore();


            wsseInterceptor(store_oro.host)


            const url = `${store_oro.host}/index_dev.php/api/create-site-costs/${this.site.id}`;


            await axios.post(url, store_calculation.needs)
                .then(() => {
                    const contract_id = vuex_store.state.updateContract.contract.id;
                    store_calculation.setNeeds([]);
                    this.refreshContract(contract_id)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A költségek mentése sikertelen volt!'
                    }
                    store_errors.addError(error)
                })

        },

        async refreshContract(contract_id) {
            const store_priceList = useContractPriceListStore();
            vuex_store.commit('updateContract/setShowAddCostModal', false, {root: true});
            await store_priceList.fetchContractPriceList(contract_id);
            await vuex_store.dispatch('updateContract/fetchContract', contract_id, {root: true});
        }

    },

    getters: {
        availableCosts: (state) => {
            return (state.priceList.filter((el) => el.categoryName === 'monthly_fee' || el.categoryName === 'install_cost'))
        },
        sumMonthly: (state) => {
            return _.sumBy(state.newCosts, 'priceMonthly')
        },
        sumOneTime: (state) => {
            return _.sumBy(state.newCosts, 'priceOneTime')
        }
    }
});


