import axios from 'axios'

import wsseToken from "@/_helper/wsse";

export default function wsseInterceptor(app){



    axios.interceptors.request.use(function (config) {

        const token = wsseToken(app.user, app.apiKey)

        config.headers = {
            'Authorization': 'WSSE profile="UsernameToken"',
            'X-WSSE': token,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }

        return config
    }, (error) => {
        return Promise.reject(error)
    })

}

