import axios from 'axios'
import wsseInterceptor from "@/_helper/wsseIntercentor";

const state = () => ({
    activeStep: 1,
    opportunity: null,
    productType: '',
    account: null,
    contact: null,
    needs: [
        {
            costType: '',
            quantity: 1
        }
    ],
    items: [],
    calculation: {
        result: {
            costs: [],
            sum: {}
        }
    },
    createdQuotation: null,
    sending: false,
    calculatorName: "calculator_2022",
    priceList: []

})

const getters = {}

const mutations = {
    setActiveStep(state, activeStep) {
        state.activeStep = activeStep
    },
    setOpportunity(state, opportunity) {
        state.opportunity = opportunity
    },
    setProductType(state, productType) {
        state.productType = productType
    },
    setAccount(state, account) {
        state.account = account
    },
    setItems(state, items) {
        state.items = items
    },
    setCreatedQuotation(state, created) {
        state.createdQuotation = created
    },
    setSending(state, sending) {
        state.sending = sending
    },
    setContact(state, contact) {
        state.contact = contact
    },
    setCalculatorName(state, calculatorName) {
        state.calculatorName = calculatorName
    },
    setCalculation(state, calculation) {
        state.calculation = calculation
    },
    setPriceList(state, priceList) {
        state.priceList = priceList
    },
    setNeeds(state, needs_arr) {
        state.needs = needs_arr
    }

}

const actions = {

    fetchOpportunity({rootState, commit}, opportunity_id) {
        wsseInterceptor(rootState.app)
        const url_opportunity = rootState.app.hostServer + '/api/opportunities/' + opportunity_id

        axios.get(url_opportunity)
            .then(resp => {
                commit('setOpportunity', resp.data)
            });

        axios.get(url_opportunity + '/account')
            .then(resp => {
                commit('setAccount', resp.data)
            });

        axios.get(url_opportunity + '/contact')
            .then(resp => {
                commit('setContact', resp.data)
            });
    },

    setProductType({dispatch, commit}, productType_id) {
        commit('setProductType', productType_id)
        dispatch('fetchPriceList', productType_id)
    },

    async fetchPriceList({rootState, commit, state}) {
        wsseInterceptor(rootState.app)
        console.log('Called fetchPriceList (vuex createQuotation)')
        const url_price_list = rootState.app.hostServer + '/api/price-list'

        const send_data = {
            priceList: {
                entity: "productType",
                entityId: state.productType
            }
        }

        await axios.post(url_price_list, send_data)
            .then(resp => {
                commit('setPriceList', resp.data)
            });
    },

    async createQuotation({rootState, commit, dispatch, state}) {
        wsseInterceptor(rootState.app)
        const url_quotation = rootState.app.hostServer + '/api/quotations'
        const quotation_core_values = {
            calculatorName: state.calculatorName,
            opportunity: state.opportunity.id,
            productType: state.productType,
            assignedUser: rootState.app.currentUser.id

        }
        await axios.post(url_quotation, quotation_core_values)
            .then((resp) => {
                commit('setCreatedQuotation', resp.data)
            })
            .then(async () => {
                for (let i = 0; i < state.calculation.result.costs.length; i++) {
                    const item_data = {
                        name: state.calculation.result.costs[i].name,
                        quantity: state.calculation.result.costs[i].quantity,
                        unitPriceMonthly: state.calculation.result.costs[i].unitPriceMonthly,
                        unitPriceOneTime: state.calculation.result.costs[i].unitPriceOneTime,
                        quotation: state.createdQuotation.id,
                        costType: state.calculation.result.costs[i].costType
                    }
                    await dispatch('createQuotationItem', item_data)
                }
            });

        window.location.href = rootState.app.navigate.return
    },

    async createQuotationItem({rootState}, data) {
        wsseInterceptor(rootState.app)
        const url_quotation_item = rootState.app.hostServer + '/api/quotationitems'

        return await axios.post(url_quotation_item, data)
            .then((resp) => {
                return resp.data
            })
    },

    async setNeeds({state, commit, dispatch}, field_values) {
        const send_data = {
            uid: 123,
            mergeCosts: true,
            priceList: {
                entity: "productType",
                entityId: state.productType
            },
            needs: []
        }

        for (let i = 0; i < field_values.length; i++) {
            const need = {
                costType: field_values[i].value.costType,
                quantity: parseInt(field_values[i].value.quantity)
            }
            send_data.needs.push(need)
        }

        commit('setNeeds', send_data.needs);

        await dispatch('calculation/fetchCalculation', send_data, {root: true})
            .then((result) => {
                commit('setCalculation', {result: result});
            })
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
