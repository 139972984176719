<template>

  <div class="ui sixteen width grid">
    <div class="six wide column"></div>

    <div class="six wide column">
      <div class="ui equal width grid">
        <starting-from-dates/>
      </div>
    </div>

    <div class="four wide right aligned  column">
      <button
          type="button"
          class="ui basic icon teal button"
          @click="copyLastValues">
        <i class="share square icon"/>
        Másolás
      </button>

      <price-changes-from-a-given-date/>
    </div>


  </div>


</template>

<script setup>
import StartingFromDates from "@/components/contract/update/contract-priceList/starting_from_dates.vue";


import PriceChangesFromAGivenDate
  from "@/components/contract/update/contract-priceList/price-changes-from-a-given-date.vue"


import {useContractPriceListStore} from "@/store-pinia/contractPriceList";

const store = useContractPriceListStore()


function copyLastValues() {
  store.copyLastPrices();
}


</script>

<style scoped>

</style>