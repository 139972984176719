<template>
  <div class="ui sixteen width grid">
    <div class="eight wide column">
      <button @click="store.setShowModal(false)"
              class="ui black button">
        Bezár
      </button>

    </div>
    <div class="eight wide right aligned column">
      <button v-if="store.oldSite"
              class="ui primary button"
              @click="store.setActiveStep(2)">Tovább
      </button>
    </div>
  </div>
</template>

<script setup>
import {useReContractSiteStore} from "@/store-pinia/reContractSite";

const store = useReContractSiteStore();

</script>

<style scoped>

</style>