<template>

  <vee-form class="ui form"
            @submit="makeSubmit"
            :initial-values="initialValues"
            :validation-schema="schema">
    <div class="ui mini input">
      <vee-field type="date"
                 placeholder="Érvényesség kezdete"
                 name="startDate"/>
    </div>

    <error-message name="startDate" class="error-message"/>

    <button
        type="submit"
        class="ui basic mini green button"
    >OK
    </button>
  </vee-form>


</template>

<script setup>

import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import * as yup from "yup";

const store = useContractPriceListStore();

const schema = yup.object({
  startDate: yup.date('Érvényes dátumra van szükség').required('Az érvényesség kezdetét meg kell admin'),
});


const initialValues = {
  startDate: store.request.startingFromDate
}


function makeSubmit(values) {

  store.setRequestChangesFromDate(values.startDate);
  store.callCreateContractPriceChange();
}


</script>

<style scoped>
.error-message {
  color: red;
}
</style>