<template>
  <i class="trash grey icon pointerCursor"
     v-if="props.cost.serviceLicenseName !=='service_fee'
                   && props.cost.status !== 'active'
                   && props.cost.status !== 'terminated'
                   && props.cost.status !== 'completed' "
     @click.stop="deleteCost(props.cost)"></i>


  <i class="envelope outline pink icon pointerCursor"
     v-if="props.cost.approveStatus ==='new' || props.cost.approveStatus === 'install_request'  "
     @click.stop="approveRequestCost(props.cost)"></i>

  <i class="envelope outline pink icon pointerCursor"
     v-if="props.cost.status === 'active' && (props.cost.terminateStatus === 'requested' || props.cost.approveStatus === 'terminate_request')"
     @click.stop="approveRequestCost(props.cost)"></i>

  <i class="thumbs down outline red icon pointerCursor"
     v-if="!props.cost.isTerminateRequested && props.cost.status === 'active'"
     @click.stop="costTerminateRequest(props.cost)"></i>

</template>

<script setup>
import {defineProps} from "vue";
import {useStore as useVuexStore} from 'vuex';


const props = defineProps(['cost']);
const vuex_store = useVuexStore()


function deleteCost(cost) {
  vuex_store.dispatch('updateContract/deleteCost', cost.id);
}

function approveRequestCost(cost) {
  alert('A kérés átmenetileg kikapcsolva')
  console.log(cost)
  //vuex_store.commit('approve/setApproveRequestInfo', {cost})
  //this.$router.push('/approve-request')
}


function costTerminateRequest(cost) {
  vuex_store.commit('updateContract/setEditedCost', cost)
  vuex_store.commit('updateContract/setShowCostTerminateRequestModal', true)
}


</script>

<style scoped>

</style>