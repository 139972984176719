<template>
  <vee-form class="ui form"
            @submit="onSubmit"
            :initial-values="initialValues()">

    <div class="ui sixteen widht grid">
      <div class="twelve wide column">

        <div class="fields">
          <div class="field">
            <vee-field type="text" placeholder="Neve:" name="name"/>
          </div>
          <div class="field">
            <vee-field type="number" placeholder="Egységára:" name="price"/>
          </div>
        </div>

      </div>

      <div class="four wide column">


        <div class="ui buttons">
          <button type="submit"
                  class="ui positive mini button">Mentés
          </button>
          <div class="or" data-text="?"></div>
          <button type="button"
                  class="ui negative mini  button"
                  @click="store.setShowNewValue(props.categoryName, false)">Mégsem
          </button>
        </div>
      </div>
    </div>

  </vee-form>


</template>

<script setup>
import {Field as VeeField, Form as VeeForm} from 'vee-validate'
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {defineProps} from "vue";

const props = defineProps(['categoryName']);
const store = useContractPriceListStore()


function initialValues() {
  return {
    name: '',
    price: 0
  }
}

function onSubmit(values) {

  const send_data = {
    name: values.name,
    price: parseInt(values.price),
    categoryName: props.categoryName
  }

  store.createNewPriceListItem(send_data);

}


</script>

<style scoped>

</style>