import {createStore} from 'vuex';

import app from '@/store/app'
import createQuotation from '@/store/createQuotation'
import createContract from '@/store/createContract'
import updateContract from '@/store/updateContract'
import customer from '@/store/customer'
import opportunity from '@/store/opportunity'
import calculation from '@/store/calculation'
import address from '@/store/address'
import approve from "@/store/approve";

const store =   createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        createQuotation,
        createContract,
        updateContract,
        approve,
        customer,
        address,
        opportunity,
        calculation
    }
});

export default store;