<template>
  <div class="ui container grid ">
    <div class="ten wide column">
      <div class="field">
        <div class="ui input">
          <vee-field as="select" class="ui fluid dropdown"
                     :name="`needs[${idx_needs}].costType`"
                     @blur="calc" @change="calc">
            <option value=""></option>
            <option v-for="needChoice in needChoices" :value="needChoice.costType" :key="needChoice.costType">
              {{
                needChoice.nameSuffix
              }}
            </option>
          </vee-field>
        </div>
        <error-message :name="`needs[${idx_needs}].costType`" class="error-message"/>
      </div>
    </div>


    <div class="four wide column">
      <div class="field">
        <div class="ui input">
          <vee-field type="number" placeholder="menny."
                     :name="`needs[${idx_needs}].quantity`"
                     @blur="calc" @change="calc"/>
        </div>
        <error-message :name="`needs[${idx_needs}].quantity`" class="error-message"/>
      </div>
    </div>

    <div class="one wide column">
      <i class="trash icon" @click="remove(idx_needs)"/>
    </div>

  </div>

</template>

<script>
import {Field, ErrorMessage} from 'vee-validate'
import {mapState} from "vuex";

export default {
  name: "NeedWidgetComponent",
  components: {
    VeeField: Field,
    ErrorMessage
  },
  props: ['errors', 'idx_needs', 'remove', 'fields'],
  computed: {
    ...mapState({
      quotation: state => state.createQuotation,
      needChoices: state => state.createQuotation.priceList
    }),
  },
  methods: {
    calc() {
      this.$emit('calculate')
    }
  }

}
</script>

<style scoped>
.row {
  margin: 5px 0;
}

.error-message {
  color: red;
}
</style>
