<template>
  <div class="ui equal width grid">
    <div class="column">
      <button class="ui black button" @click="store.setShowModal(false)">Mégsem</button>
    </div>


    <div class="column">
      <button @click="store.selectNewSite()"
              class="ui basic teal button"> Másik telephely választása
      </button>
    </div>
    <div class="column">
      <button class="ui red button" v-if="store.reContractDate"
              :disabled="store.sending"
              @click="makeReContract">
        <i class="share square white icon"></i>
        Az átszerződés létrehozása
      </button>
    </div>

  </div>
</template>

<script setup>

import {useReContractSiteStore} from "@/store-pinia/reContractSite";

const store = useReContractSiteStore();

function makeReContract() {

  const re_contract_data = {

    oldSiteId: parseInt(store.oldSite.id),
    toContractId: parseInt(store.newSiteContract.id),
    reContractInstallDate: store.reContractDate
  }

  store.createReContract(re_contract_data);

}


</script>

<style scoped>

</style>