// import axios from "axios";
// import router from "@/router";
// import wsseInterceptor from "@/_helper/wsseIntercentor";

const state = () => ({
    activeStep: 1,
    serviceCompany: '',
    customer: null,
    accountCustomers: [],
    quotations: [],
    quotation: null,
    opportunity: null,
    opportunityIsReady: false,
    dateOfContract: (new Date()).toISOString().split('T')[0],
    payMethod: 'bank',
    prompt: 8,
    billingPeriod: "-1",
    isTrial: false,
    noticePeriodDay: 30,
    reActivateFee: 15000,
    loyaltyName: 'loyalty_year_1',

    billEmails: [""],
    contractItems: [
        {
            "name": "",
            "city": "",
            "street": "",
            "createdAddress": null,
            "needs": [
                {"costType": "", "quantity": 1},
            ],
            "costs": []

        }
    ],

    calculationResults: [],

    productTypeInfo: null,
    productTypeCosts: [],
    contactPersons: [],
    delegatePerson: '',
    financialPerson: '',
    technicalPerson: '',
    intentSelectPerson: null,
    showPersonsList: false,
    productType: '',
    salesMan: null,
    account: null,
    contact: null,
    sending: false,
    createdContract: null,
    zipCode: '',
    city: '',
    street: '',
    email: '',
    approvePerson: {
        name: '',
        title: '',
        email: '',
        phone: ''
    }

})

const getters = {}

const mutations = {
    setActiveStep(state, newStep) {
        state.activeStep = newStep
    },
    setServiceCompany(state, serviceCompany) {
        state.serviceCompany = serviceCompany
    },
    setQuotations(state, quotations) {
        state.quotations = quotations
    },
    setQuotation(state, quotation) {
        state.quotation = quotation
    },
    setOpportunity(state, opportunity) {
        state.opportunity = opportunity
    },
    setAccount(state, account) {
        state.account = account
    },
    setContact(state, contact) {
        state.contact = contact
    },
    setDateOfContract(state, dateOfContract) {
        state.dateOfContract = dateOfContract
    },
    setCustomer(state, customer) {
        state.customer = customer
    },
    setPayMethod(state, payMethod) {
        state.payMethod = payMethod
    },
    setPrompt(state, prompt) {
        state.prompt = prompt
    },
    setBillingPeriod(state, period) {
        state.billingPeriod = period
    },
    setIsTrial(state, isTrial) {
        state.isTrial = isTrial
    },
    setNoticePeriodDay(state, noticePeriodDay) {
        state.noticePeriodDay = noticePeriodDay
    },
    setReActivateFee(state, reActivateFee) {
        state.reActivateFee = reActivateFee
    },
    setLoyaltyName(state, loyalty) {
        state.loyaltyName = loyalty
    },
    setBillEmails(state, billEmails) {
        state.billEmails = billEmails
    },
    setItems(state, items) {
        state.items = items
    },
    setSending(state, value) {
        state.sending = value
    },
    setCreatedContract(state, createdContract) {
        state.createdContract = createdContract
    },
    setProductType(state, productType) {
        state.productType = productType
    },
    setAccountCustomers(state, accountCustomers) {
        state.accountCustomers = accountCustomers
    },
    setDelegatePerson(state, person) {
        state.delegatePerson = person
    },
    setFinancialPerson(state, person) {
        state.financialPerson = person
    },
    setTechnicalPerson(state, person) {
        state.technicalPerson = person
    },
    setEmail(state, email) {
        state.email = email
    },
    setZipCode(state, zipCode) {
        state.zipCode = zipCode
    },
    setCity(state, city) {
        state.city = city
    },
    setStreet(state, street) {
        state.street = street
    },
    setCreatedAddress(state, createdAddress) {
        state.contractItems[createdAddress.index] = createdAddress.id
    },
    addContactPerson(state, contactPerson) {
        state.contactPersons.push(contactPerson)
    },
    setProductTypeInfo(state, info) {
        state.productTypeInfo = info
    },
    setProductTypeCosts(state, costs) {
        state.productTypeCosts = costs
    },
    setIntentSelectPerson(state, intent) {
        state.intentSelectPerson = intent
    },
    setShowPersonsList(state, show) {
        state.showPersonsList = show
    },
    setCalculationResult(state, calculationResult) {
        state.calculationResults[calculationResult.idx] = calculationResult.result
    },
    saveItems(state, items) {
        state.contractItems = items
    },
    setOpportunityIsReady(state, isReady) {
        state.opportunityIsReady = isReady
    },
    setApprovePerson(state, approvePerson) {
        state.approvePerson = approvePerson
    }

}

const actions = {
    // async fetchOpportunity({rootState, commit}, opportunity_id) {
    //
    //     commit("setOpportunityIsReady", false)
    //
    //     wsseInterceptor(rootState.app)
    //     const url_opportunity = rootState.app.hostServer + '/api/opportunities/' + opportunity_id
    //
    //     await axios.get(url_opportunity)
    //         .then(resp => {
    //             commit('setOpportunity', resp.data)
    //         });
    //
    //
    // },

    // async createBillEmail({rootState}, data) {
    //     wsseInterceptor(rootState.app)
    //     const url_billrecipientemails = rootState.app.hostServer + '/api/billrecipientemails'
    //     await axios.post(url_billrecipientemails, data)
    // },

    // async createAddress({rootState}, data) {
    //     wsseInterceptor(rootState.app)
    //     const url_address = rootState.app.hostServer + '/api/addresses'
    //     return await axios.post(url_address, data)
    //         .then((resp) => {
    //             return resp.data
    //         })
    // },

    // async createContractItem({rootState, dispatch}, data) {
    //     wsseInterceptor(rootState.app)
    //     const url_item = rootState.app.hostServer + '/api/contractitems'
    //
    //     const address_data = {
    //         postalCode: 0,
    //         city: data.city,
    //         street: data.street,
    //         country: 'HU'
    //     }
    //
    //     const created_address = await dispatch('createAddress', address_data)
    //
    //     const sending_item_data = {
    //         contract: data.contract,
    //         nameAtContract: data.name,
    //         endOfLoyalty: null,
    //         address: created_address.id
    //     }
    //
    //     const created_item = await axios.post(url_item, sending_item_data)
    //         .then((resp) => {
    //             return resp.data
    //         })
    //
    //     for (let i = 0; i < data.costs.length; i++) {
    //         const cost_data = data.costs[i]
    //         dispatch('createItemCost', {item: created_item.id, ...cost_data})
    //     }
    // },

    // async createItemCost({rootState}, data) {
    //     wsseInterceptor(rootState.app)
    //     const url_cost = rootState.app.hostServer + '/api/itemcosts'
    //
    //     // console.log('CREATE-ITEM-COST data', data)
    //
    //     const send_data = {
    //         item: data.item,
    //         name: data.name,
    //         unitPriceMonthly: data.priceMonthly,
    //         unitPriceOneTime: data.priceOneTime,
    //         costType: parseInt(data.costType)
    //     }
    //     return await axios.post(url_cost, send_data)
    //         .then((resp) => {
    //             return resp.data
    //         })
    // },

    // async fetchQuotationsByOpportunity({rootState, commit}, opportunity_id) {
    //     wsseInterceptor(rootState.app)
    //     const url_quotations = rootState.app.hostServer + '/api/quotations?filter[opportunity]=' + opportunity_id;
    //
    //     await axios.get(url_quotations)
    //         .then(resp => {
    //             commit('setQuotations', resp.data)
    //         });
    // },


    // async fetchContactPerson({rootState, commit}, person_id) {
    //     wsseInterceptor(rootState.app)
    //     const url_contactPerson = rootState.app.hostServer + '/api/contacts/' + person_id;
    //     await axios.get(url_contactPerson)
    //         .then(resp => {
    //             commit('addContactPerson', resp.data)
    //         })
    // },

    // async fetchProductType({rootState, commit}, product_type_id) {
    //     wsseInterceptor(rootState.app)
    //     const url_productType = rootState.app.hostServer + '/api/adminproducttypeentities/' + product_type_id;
    //     await axios.get(url_productType)
    //         .then(resp => {
    //             commit('setProductTypeInfo', resp.data)
    //         })
    // },

    setQuotation({commit}, quotation) {
        commit('setQuotation', quotation)

    },

    setProductType({commit, dispatch}, product_type_id) {
        commit('setProductType', product_type_id)
        dispatch('fetchProductType', product_type_id)
    },

    // async createSimpleContract({commit, state, dispatch, rootState}) {
    //
    //     commit('setSending', true)
    //
    //     wsseInterceptor(rootState.app)
    //     const url_contract = rootState.app.hostServer + '/api/simplecontracts'
    //
    //     const contract_core_values = {
    //         "dateOfContract": state.dateOfContract,
    //         "isTrial": state.isTrial,
    //         "noticePeriodDay": state.noticePeriodDay,
    //         "reActivateFee": state.reActivateFee,
    //         "billingPeriod": state.billingPeriod,
    //         "payMethod": state.payMethod,
    //         "prompt": state.prompt,
    //         "quotation": state.quotation ? state.quotation.id : null,
    //         "productType": state.productType,
    //         "salesMan": rootState.app.currentUser.id,
    //         "assignedSalesMan": rootState.app.currentUser.id,
    //         "createdBy": rootState.app.currentUser.id,
    //         "updatedBy": rootState.app.currentUser.id,
    //         "loyaltyName": state.loyaltyName,
    //         "serviceCompany": state.serviceCompany,
    //         "customer": rootState.customer.customer.id,
    //         "delegatePerson": rootState.customer.customer.delegatePerson,
    //         "financialPerson": rootState.customer.customer.financialPerson,
    //         "technicalPerson": rootState.customer.customer.technicalPerson
    //     }
    //
    //     await axios.post(url_contract, contract_core_values)
    //         .then((resp) => {
    //             commit('setCreatedContract', resp.data)
    //         })
    //         .then(async () => {
    //             for (let i = 0; i < state.billEmails.length; i++) {
    //                 const data = {
    //                     "contract": state.createdContract.id,
    //                     "email": state.billEmails[i]
    //                 }
    //                 await dispatch('createBillEmail', data)
    //             }
    //
    //             for (let i = 0; i < state.contractItems.length; i++) {
    //                 await dispatch('createContractItem', {contract: state.createdContract.id, ...state.contractItems[i]})
    //             }
    //         })
    //         .then(() => {
    //             commit('app/setHostServerParams', {
    //                 reason: 'CONTRACT_UPDATE',
    //                 values: {
    //                     contract: state.createdContract.id
    //                 }
    //             }, {root: true})
    //             router.push(`/contract/update/${state.createdContract.id}`)
    //         });
    //
    // },

    // async fetchPriceList({rootState, commit}, priceList) {
    //
    //     console.log('Called fetchPriceList (vuex createContract)')
    //
    //     wsseInterceptor(rootState.app)
    //     const url_price_list = rootState.app.hostServer + '/api/price-list'
    //
    //     const send_data = {
    //         priceList: priceList
    //     }
    //
    //     await axios.post(url_price_list, send_data)
    //         .then(resp => {
    //             commit('setProductTypeCosts', resp.data)
    //         });
    // },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

