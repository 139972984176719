<template>

  <Teleport to="body">
    <modal :show="store.showModal" :size="'modal-medium'"
           @close="onCloseModal">
      <template #header>{{ header }}</template>
      <template #body>

        <div v-if="store.activeStep===1">
                    <search-site/>
                    <found-sites/>
                    <search-actions/>

        </div>

        <div v-if="store.activeStep===2">
                    <site-info/>
                    <re-contract-date/>
                    <selected-site-actions/>
        </div>


      </template>
    </modal>

  </Teleport>
</template>

<script >
import Modal from "@/components/slots/modal.vue"

import {useReContractSiteStore} from "@/store-pinia/reContractSite";



  import SearchSite from "@/components/contract/update/reContract/search/searchSite.vue";
  import FoundSites from "@/components/contract/update/reContract/search/foundSites.vue";
  import SearchActions from "@/components/contract/update/reContract/search/searchActions.vue";
 import SiteInfo from "@/components/contract/update/reContract/selectedSite/siteInfo.vue";
 import SelectedSiteActions from "@/components/contract/update/reContract/selectedSite/selectedSiteActions.vue";
 import ReContractDate from "@/components/contract/update/reContract/selectedSite/reContractDate.vue";


export default {
  name: "modal-reContract-site",

  setup() {
   const store = useReContractSiteStore();

    return {
      store
    }
  },

  components: {
    ReContractDate,
    SearchActions,
    SelectedSiteActions,
    SiteInfo,
    FoundSites,
    SearchSite,
    Modal
  },
  methods: {
    onCloseModal() {
       this.store.setShowModal(false);
      this.$store.commit('updateContract/setEditedCost', null)
    }
  },
  computed: {
    header: () => `Átszerződés másik cégtől`

  }

}
</script>



<style scoped>

</style>
