<template>
  <vee-form class="ui form"
            @submit="onConditionsSubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{errors}">

<!--       <pre>{{ contract }}</pre>-->

    <div class="ui grid">
      <div class="eight wide column">
        <div class="field" :class="{error: `dateOfContract` in errors}">
          <label>Szerződéskötési dátum</label>
          <div class="ui input">
            <vee-field type="date" name="dateOfContract"/>
          </div>
          <error-message name="dateOfContract" class="error-message"/>
        </div>

<!--        <div class="field" :class="{error: 'isTrial' in errors}">-->
<!--          <label>Próba szerződés?</label>-->

<!--          <div class="ui input">-->
<!--            <vee-field type="checkbox" name="isTrial"/>-->
<!--          </div>-->

<!--          <error-message name="isTrial" class="error-message"/>-->
<!--        </div>-->


        <div class="field" :class="{error: 'isTrial' in errors}">
          <label>Próba szerződés?</label>

          <div class="ui input">
            <vee-field as="select" class="ui fluid dropdown" name="isTrial">
              <option value="false">Nem</option>
              <option value="true">Igen, próba szerződés</option>
            </vee-field>
          </div>
          <error-message name="isTrial" class="error-message"/>
        </div>




        <div class="field" :class="{error: 'noticePeriodDay' in errors}">
          <label>Felmondási idő (napokban)</label>
          <div class="ui input">
            <vee-field type="number" name="noticePeriodDay"/>
          </div>
          <error-message name="noticePeriodDay" class="error-message"/>
        </div>

        <div class="field" :class="{error: 'reActivateFee' in errors}">
          <label>Újraaktiválás költsége</label>
          <div class="ui input">
            <vee-field type="number" name="reActivateFee"/>
          </div>
          <error-message name="reActivateFee" class="error-message"/>
        </div>
      </div>


      <div class="eight wide column">
        <div class="field" :class="{error: 'payMethod' in errors}">
          <label>Fizetési mód</label>
          <div class="ui input">
            <vee-field as="select" class="ui fluid dropdown" name="payMethod">
              <option value="bank">Átutalás útján</option>
              <option value="cash">Kp-ben</option>
            </vee-field>
          </div>
          <error-message name="payMethod" class="error-message"/>
        </div>

        <div class="field" :class="{error: 'billingPeriod' in errors}">
          <label>Gyakoriság</label>
          <div class="ui input">
            <vee-field as="select" class="ui fluid dropdown" name="billingPeriod">
              <option value="-1">Utólag 1 hónap</option>
              <option value="1">Előre 1 hónap</option>
              <option value="2">Előre 2 hónap</option>
              <option value="3">Előre negyed év (3 hónap)</option>
              <option value="6">Előre fél év (6 hónap)</option>
              <option value="12">Előre egy év (12 hónap)</option>
              <option value="24">Előre KETTŐ év (24 hónap)</option>
              <option value="26">Előre HÁROM év (36 hónap)</option>
            </vee-field>
          </div>
          <error-message name="billingPeriod" class="error-message"/>
        </div>


        <div class="field" :class="{error: 'prompt' in errors}">
          <label>Fizetési határidő napokban</label>
          <div class="ui input">
            <vee-field as="select" class="ui fluid dropdown" name="prompt">
              <option value="8">8 nap</option>
              <option value="15">15 nap</option>
              <option value="28">28 nap</option>
              <option value="45">45 nap</option>
              <option value="60">60 nap</option>
            </vee-field>
          </div>
          <error-message name="prompt" class="error-message"/>
        </div>

        <div class="field" :class="{error: 'loyaltyName' in errors}">
          <label>Hűségidő</label>
          <div class="ui input">
            <vee-field as="select" class="ui fluid dropdown" name="loyaltyName">
              <option value="loyalty_year_1">1 év</option>
              <option value="loyalty_year_2">2 év</option>
              <option value="loyalty_year_3">3 év</option>
            </vee-field>
          </div>
          <error-message name="loyaltyName" class="error-message"/>
        </div>


      </div>
    </div>


    <div>
      <div class="actions">
        <button class="ui black deny button"
                @click="$emit('close')">
          Mégsem
        </button>

        <button type="submit"
                class="ui positive right labeled icon button">
          Mentés
          <i class="checkmark icon"></i>
        </button>

      </div>
    </div>


  </vee-form>


</template>

<script>
import {Form, Field} from 'vee-validate';
import * as yup from "yup";
import {mapState} from "vuex";

export default {
  name: "conditions-edit-form",
  components: {
    VeeForm: Form,
    VeeField: Field
  },
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,
    })

  },

  data() {
    return {
      schema: yup.object().shape(
          {
            dateOfContract: yup.date().required('A szerződéskötés dátuma szükséges'),
            isTrial: yup.string().required('Megadása kötelező'),
            payMethod: yup.string().required('A fizetési mód megadása kötelező'),
            prompt: yup.number().required('A fizetési napok számát meg kell adni'),
            billingPeriod: yup.number().required('A fizetési határidő megadása kötelező'),
            noticePeriodDay: yup.number().required('A felmondási idő megadása kötelező'),
            reActivateFee: yup.number().required('A költség megadása kötelező'),
            loyaltyName: yup.string().required('A hűségidő megadása kötelező'),
          }
      ),
      initialValues: {
        dateOfContract: this.$store.state.updateContract.contract.dateOfContract,
        isTrial: this.$store.state.updateContract.contract.isTrial,
        payMethod: this.$store.state.updateContract.contract.payMethod,
        prompt: this.$store.state.updateContract.contract.prompt,
        billingPeriod: this.$store.state.updateContract.contract.billingPeriod,
        noticePeriodDay: this.$store.state.updateContract.contract.noticePeriodDay,
        reActivateFee: this.$store.state.updateContract.contract.reActivateFee,
        loyaltyName: this.$store.state.updateContract.contract.loyalty
      },
    }

  },

  methods: {
    onConditionsSubmit(values) {

      console.log(values)
       const send_data_contract = {
         id: this.$store.state.updateContract.contract.id,
         ...values
       }

       console.log('Szerződéses adatok:', send_data_contract)

       this.$store.dispatch('updateContract/updateContract', send_data_contract)

      this.$emit('close')

    }
  }
}
</script>

<style scoped>

</style>
