<template>

  <cost-header :costName="'Havidíjak'"/>

  <div class="ui container grid">
    <div class="ui container grid">
      <div class="sixteen wide column">
        <div class="ui divided list">
          <div v-for="(cost) in store.monthlyFees" :key="cost.id" class="item">
            <price-list-item-row :cost="cost"  :fields="props.fields"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ui sixteen width grid">
    <template v-if="!store.showNew.monthly_fee">
      <div class="sixteen wide column">
        <button class="ui green basic mini icon button "
                @click="store.setShowNewValue('monthly_fee', true)">
          <i class="plus green icon"></i>
          Új havidíj hozzáadása
        </button>
      </div>
    </template>

    <template v-else>
      <create-new-price :categoryName="'monthly_fee'"/>
    </template>
  </div>


</template>

<script setup>
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import CostHeader from "@/components/contract/update/contract-priceList/cost-header.vue";

import CreateNewPrice from "@/components/contract/update/contract-priceList/create-new-price.vue";
import PriceListItemRow from "@/components/contract/update/contract-priceList/price-list-item-row.vue";
import {defineProps} from "vue";

const props = defineProps(['fields']);
const store = useContractPriceListStore()


</script>

<style scoped>

</style>