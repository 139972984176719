import {defineStore} from "pinia";
import wsseInterceptor from "@/_helper/wsseIntercentor";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import {useCostDetailingStore} from "@/store-pinia/costsDetailing";
import {useCostsStore} from "@/store-pinia/api/costs";
import {useCalculationStore} from "@/store-pinia/calculation";


const url_contract_price_list = '/api/contract-price-list';


export const useContractPriceListStore = defineStore({
    id: 'contractPriceList',
    state: () => {
        return {
            contractId: null,
            contract: null,
            priceList: [],
            editedChange: null,

            request: {
                startingFromDate: null,
                changesFromDate: null
            },

            showNew: {
                monthly_fee: false,
                install_cost: false,
                service_fee: false
            }
        }
    },
    actions: {
        setContractId(contract_id) {
            this.contractId = contract_id
        },

        setContract(contract) {
            this.contract = contract
        },


        setPriceList(price_list) {
            this.priceList = price_list
        },

        setShowNewValue(categoryName, show_value) {

            if (categoryName === 'monthly_fee') {
                this.showNew.monthly_fee = show_value
            } else if (categoryName === 'install_cost') {
                this.showNew.install_cost = show_value
            }
            if (categoryName === 'service_fee') {
                this.showNew.service_fee = show_value
            }
        },
        setHideAllShowNewValues() {
            this.setShowNewValue('monthly_fee', false);
            this.setShowNewValue('install_cost', false);
            this.setShowNewValue('service_fee', false);
        },

        setEditedChange(edited_change_item) {
            this.editedChange = edited_change_item;
        },


        setRequestStartingFromDate(request_starting_date) {
            this.request.startingFromDate = request_starting_date
        },

        setRequestChangesFromDate(changes_from_date) {
            this.request.changesFromDate = changes_from_date
        },


        prepareForPriceChange() {
            this.priceList.map(el => el.newPriceRequest = null)
        },

        setNewPriceRequest(cost, new_price_request) {
            const index = this.priceList.findIndex(el => el.id === cost.id)
            const new_priceList = [...this.priceList]
            cost.newPriceRequest = new_price_request
            new_priceList[index].cost
            this.setPriceList(new_priceList)
        },


        copyLastPrices() {
            this.priceList.map(el => el.newPriceRequest = el.priceChanges[el.priceChanges.length - 1].price)
            const new_priceList = [...this.priceList]
            this.setPriceList(new_priceList)
        },


        //############################
        async fetchContractPriceList(contract_id) {

            this.setContractId(contract_id)

            const store_oro = useOroStore();

            const store_errors = useErrorsStore();

            wsseInterceptor(store_oro.host)

            const url = `${store_oro.host}${url_contract_price_list}/${contract_id}`;

            await axios.get(url)
                .then(resp => {
                    this.setPriceList(resp.data)
                })
                .then(() => {
                    const store_cost_detailing = useCostDetailingStore();
                    store_cost_detailing.setPriceList(this.priceList)
                    const store_costs = useCostsStore();
                    store_costs.setPriceList(this.priceList)
                    const store_calculation = useCalculationStore();
                    store_calculation.setPriceList(this.priceList)
                })
                .then(() => {
                    this.prepareForPriceChange()
                    console.log(this.priceList)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árlista elérése sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },

//############################           ELSŐ érvényesség beállítása             #######################################
        async callContractPricesStartingFromDate(data) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            wsseInterceptor(store_oro.host)

            const contract_id = this.contractId;

            const url = `${store_oro.host}/api/contract-prices-starting-from-date/${contract_id}`;

            const send_data = {
                validFromDate: data.validFromDate
            }

            await axios.post(url, send_data)
                .then(() => {
                    this.fetchContractPriceList(this.contractId);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árlista kezdeti érvényessági idjének a beállítása sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },

// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx              ÁRVÁLTOZÁS beállítása           xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

        async callCreateContractPriceChange() {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            wsseInterceptor(store_oro.host)

            const contract_id = this.contractId;

            const url = `${store_oro.host}/index_dev.php/api/create-contract-price-change/${contract_id}`;

            const contractPrices = [];

            this.priceList.map(el => {
                const change = {
                    id: parseInt(el.id),
                    newPrice: parseInt(el.newPriceRequest)
                }
                contractPrices.push(change)
            })


            const send_data = {

                validFromDate: this.request.changesFromDate,
                contractPrices: contractPrices
            }
            await axios.post(url, send_data)
                .then(() => {
                    this.fetchContractPriceList(contract_id);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árváltozás beállítása sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },


// xxxxxxxxxxxxxxxxxxxxxxxxxxxxx

        async patchPriceListChange(priceListChange_id, patch_data) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            wsseInterceptor(store_oro.host)

            const url = `${store_oro.host}/api/update-price-change-item/${priceListChange_id}`;

            await axios.post(url, patch_data)
                .then(() => {
                    this.fetchContractPriceList(this.contractId)
                })
                .then(() => {
                    this.setEditedChange(null)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árlista-bejegyzés frissítése sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },

//############################
        async createNewPriceListItem(new_priceListItem) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            wsseInterceptor(store_oro.host)

            const url = `${store_oro.host}/api/create-new-contract-price/${this.contractId}`;

            await axios.post(url, new_priceListItem)
                .then(() => {
                    this.fetchContractPriceList(this.contractId)
                })
                .then(() => {
                        this.setEditedChange(null)
                        this.setHideAllShowNewValues();
                    }
                )

                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árlista-bejegyzés hozzáadása sikertelem!'
                    }
                    store_errors.addError(error)
                })
        },

    },

    getters: {
        monthlyFees: (state) => {
            return (state.priceList.filter((el) => el.categoryName === 'monthly_fee'))
        },
        installCosts: (state) => {
            return (state.priceList.filter((el) => el.categoryName === 'install_cost'))
        },
        notCategorizedCosts: (state) => {
            return (state.priceList.filter((el) => el.categoryName === '???'))
        },
        serviceFees: (state) => {
            return (state.priceList.filter((el) => el.categoryName === 'service_fee'))
        },
    }
});


