<template>

  <div class="ui middle aligned divided list">
    <div class="item pointer" v-for="site in store.foundSites" :key="site.id">
      <div class="content"
           @click="store.setOldSite(site)">
        <div class="ui sixteen width grid">
          <div class="six wide column">
            {{ site.nameAtContract }}, {{ site.address.city }} {{ site.address.street }}
          </div>

          <!--          <div class="five wide column">-->
          <!--            {{ site.customer.name }}-->
          <!--          </div>-->
          <!--          <div class="three wide column">-->
          <!--            {{ site.serviceCompany.name }}-->
          <!--          </div>-->
          <!--          <div class="two wide right aligned column">-->
          <!--            {{ site.monthlyCost }} Ft/hó-->
          <!--          </div>-->


        </div>
      </div>
    </div>
  </div>


</template>

<script setup>
import {useReContractSiteStore} from "@/store-pinia/reContractSite";

const store = useReContractSiteStore();


</script>

<style scoped>
.pointer {
  cursor: pointer
}


</style>