import axios from "axios";
import wsseInterceptor from "@/_helper/wsseIntercentor";
import {useReContractSiteStore} from "@/store-pinia/reContractSite";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {useContractStore} from "@/store-pinia/api/contract";
import {useCalculationStore} from "@/store-pinia/calculation";

const state = () => ({

    contract: null,
    activeAccordionIndex: -1,
    contractIsReady: false,

    editedSite: null,
    editedCost: null,
    calculationResult: [],
    priceList: [],
    sending: false,


    showNewSiteModal: false,
    showEditSiteModal: false,
    showAddCostModal: false,
    showAddCostModalFromPriceList: false,
    showPriceListModal: false,
    showCostsDetailingModal: false,
    showEditConditionsModal: false,
    showSiteTerminateRequestModal: false,
    showCostTerminateRequestModal: false,


    acceptTerminateDays: 14,

    transitionSites: [],

    test: 'TESZT ELEK'

})

const getters = {

    // liveItems(state) {
    //     return state.contract.sites.filter(item => item.serviceStopDate === null)
    // },
    // activeItems(state) {
    //     return state.contract.sites.filter(item => item.serviceStartDate !== null && item.serviceStopDate === null)
    // },
    // terminatedItems(state) {
    //     return state.contract.sites.filter(item => item.serviceStopDate !== null)
    // }

}

const mutations = {
    setContract(state, contract) {
        const pinia_priceListStore = useContractPriceListStore();
        const pinia_contractStore = useContractStore();

        state.contract = contract
        pinia_priceListStore.setContract(contract)
        pinia_contractStore.setContract(contract)
    },
    setActiveAccordionIndex(state, active_index) {
        state.activeAccordionIndex = active_index
    },
    setContractIsReady(state, is_ready) {
        state.contractIsReady = is_ready
    },
    setEditedSite(state, site) {
        state.editedSite = site
    },
    setEditedCost(state, cost) {
        state.editedCost = cost
    },
    setCalculationResult(state, calculationResult) {
        state.calculationResult = calculationResult
    },
    setPriceList(state, priceList) {
        state.priceList = priceList
    },
    setSending(state, sending) {
        state.sending = sending
    },
    setShowNewSiteModal(state, showModal) {
        state.showNewSiteModal = showModal
    },
    setShowEditSiteModal(state, showModal) {
        state.showEditSiteModal = showModal
    },
    // setShowEditCostModal(state, showModal) {
    //     state.showEditCostModal = showModal
    // },
    setShowEditConditionsModal(state, showModal) {
        state.showEditConditionsModal = showModal
    },
    setShowSiteTerminateRequestModal(state, showModal) {
        state.showSiteTerminateRequestModal = showModal
    },
    setShowCostTerminateRequestModal(state, showModal) {
        state.showCostTerminateRequestModal = showModal
    },
    setShowAddCostModal(state, showModal) {
        state.showAddCostModal = showModal
    },
    setShowAddCostFromPriceListModal(state, showModal) {
        state.showAddCostModalFromPriceList = showModal
    },
    setShowPriceListModal(state, showModal) {
        state.showPriceListModal = showModal
    },
    setShowCostsDetailingModal(state, showModal) {
        state.showCostsDetailingModal = showModal
    },
    setTransitionSites(state, transitions_sites) {
        state.transitionSites = transitions_sites
    },
    addToTransitionSites(state, transition_site_id) {
        state.transitionSites.push(transition_site_id)
    },
    removeFromTransitionSites(state, transition_site_id) {
        state.transitionSites = state.transitionSites.filter(el => el !== transition_site_id);
    },

    setTestElek(state, test_msg) {
        state.test = test_msg
    },

}

const actions = {

    async fetchContract({rootState, commit}, contract_id) {
        const store_reContract = useReContractSiteStore();
        const store_calculation = useCalculationStore();
        commit("setContractIsReady", false)

        wsseInterceptor(rootState.app)
        const url_contract = rootState.app.hostServer + '/api/contract-info/' + contract_id

        await axios.get(url_contract)
            .then(resp => {
                commit('setContract', resp.data)
                store_reContract.setNewSiteContract(resp.data);
                store_calculation.setPriceList(resp.data.priceList)
            })

        commit("setContractIsReady", true)
    },

    async deleteContractItem({rootState, dispatch}, item) {

        wsseInterceptor(rootState.app)
        const url_contract_item = rootState.app.hostServer + '/api/contractitems/' + item.id

        await axios.delete(url_contract_item)
        await dispatch('fetchContract', item.contract)
    },

    async fetchProductType({rootState, commit}, product_type_id) {
        wsseInterceptor(rootState.app)
        const url_productType = rootState.app.hostServer + '/api/adminproducttypeentities/' + product_type_id;
        await axios.get(url_productType)
            .then(resp => {
                commit('setProductTypeInfo', resp.data)
            })
    },

    setProductTypeId({commit, dispatch}, product_type_id) {
        commit('setProductTypeId', product_type_id)
        dispatch('fetchProductType', product_type_id)
    },

    async fetchPriceList({rootState, commit, state}, loyalty) {

        console.log('Called fetchPriceList (vuex updateContract)')

        wsseInterceptor(rootState.app)
        const url_price_list = rootState.app.hostServer + '/api/price-list'

        const priceList = {
            entity: "contract",
            entityId: state.contract.id,
            loyalty: loyalty
        }

        const send_data = {
            priceList: priceList
        }

        await axios.post(url_price_list, send_data)
            .then(resp => {
                commit('setPriceList', resp.data)
            });
    },

    async saveNewContractItems({state, dispatch, commit}) {

        commit('setSending', true)

        for (let i = 0; i < state.newItems.length; i++) {
            await dispatch('createContractItem', {contract: state.contract.id, ...state.newItems[i]})
        }

        await commit('saveNewItems', [])

        await dispatch('fetchContract', state.contract.id)

        await commit('setSending', false)

    },

    async createSite({rootState, state, dispatch, commit}, data) {

        commit('setSending', true);

        wsseInterceptor(rootState.app)
        const url_item = rootState.app.hostServer + '/api/contractitems'

        const address_data = {
            postalCode: 0,
            city: data.city,
            street: data.street,
            country: 'HU'
        }

        const created_address = await dispatch('createAddress', address_data)

        const sending_site_data = {
            contract: state.contract.id,
            nameAtContract: data.name,
            endOfLoyalty: null,
            address: created_address.id
        }

        const created_site = await axios.post(url_item, sending_site_data)
            .then((resp) => {
                return resp.data
            })

        for (let i = 0; i < state.calculationResult.costs.length; i++) {
            const cost_data = state.calculationResult.costs[i]
            await dispatch('createItemCost', {item: created_site.id, ...cost_data})
        }

        await dispatch('fetchContract', state.contract.id)

        commit('setSending', false);
    },

    async createItemCost({rootState}, data) {
        wsseInterceptor(rootState.app)
        const url_cost = rootState.app.hostServer + '/api/itemcosts'
        const send_data = {
            item: data.item,
            name: data.name,
            unitPriceMonthly: data.priceMonthly,
            unitPriceOneTime: data.priceOneTime,
            costType: data.costType
        }

        console.log('item_costs_send_data', send_data)

        return await axios.post(url_cost, send_data)
            .then((resp) => {
                return resp.data
            })
    },

    async createAddress({rootState}, data) {
        wsseInterceptor(rootState.app)
        const url_address = rootState.app.hostServer + '/api/addresses'
        return await axios.post(url_address, data)
            .then((resp) => {
                return resp.data
            })
    },

    async deleteCost({rootState, dispatch, state}, cost_id) {
        wsseInterceptor(rootState.app)
        const url_item_cost = rootState.app.hostServer + '/api/itemcosts/' + cost_id
        await axios.delete(url_item_cost)
            .then(() => {
                dispatch("fetchContract", state.contract.id)
            })
    },

    async updateItem({rootState, dispatch, state}, site) {
        wsseInterceptor(rootState.app)
        const url_item = rootState.app.hostServer + '/api/contractitems/' + site.id

        await axios.patch(url_item, {nameAtContract: site.nameAtContract})
            .then(() => {
                const address = site.address
                dispatch('updateAddress', address)
            })
            .then(() => {
                dispatch("fetchContract", state.contract.id)
            })
    },

    async deleteSite({rootState, commit, dispatch, state}, site) {
        wsseInterceptor(rootState.app)
        commit('setSending', true);
        const url_site = rootState.app.hostServer + '/api/contractitems/' + site.id
        console.log('url_site: ', url_site);

        await axios.delete(url_site)
            .then(() => {
                dispatch('fetchContract', state.contract.id)
            });

        await commit('setSending', false);
    },

    async updateAddress({rootState}, address) {
        wsseInterceptor(rootState.app)
        const url_address = rootState.app.hostServer + '/api/addresses/' + address.id

        const send_address_data = {...address}
        delete send_address_data.id
        return await axios.patch(url_address, send_address_data)
            .then((response) => {
                return response.data
            })
    },

    async updateContract({rootState, dispatch}, contract) {

        const send_data = {...contract}
        delete send_data.id

        wsseInterceptor(rootState.app)
        const url_contract = rootState.app.hostServer + '/api/simplecontracts/' + contract.id

        await axios.patch(url_contract, send_data)
            .then(() => {
                dispatch("fetchContract", contract.id)
            })
    },

    async terminateRequest({rootState, dispatch, commit}, data) {

        let url_terminate_request = '';

        if (data.scope === 'site') {
            url_terminate_request = rootState.app.serviceAdminServer + '/terminate-request-site/' + data.site.id;
        }

        if (data.scope === 'cost') {
            url_terminate_request = rootState.app.serviceAdminServer + '/terminate-request-cost/' + data.cost.id;
        }


        const contract_id = data.contract_id

        wsseInterceptor(rootState.app)

        await axios.post(url_terminate_request, data)
            .then(() => {
                commit('setShowSiteTerminateRequestModal', false)
                commit('setShowCostTerminateRequestModal', false)
            })
            .then(() => {
                dispatch('fetchContract', contract_id)
            })
    },

    async addCost({dispatch, commit, state}) {

        const new_costs = state.calculationResult.costs
        const site_id = state.editedSite.id

        for (let i = 0; i < new_costs.length; i++) {
            const cost_data = new_costs[i]
            await dispatch('createItemCost', {item: site_id, ...cost_data})
        }


        commit('setShowAddCostModal', false)
        commit('setEditedSite', null)
        dispatch('fetchContract', state.contract.id)

    },

    toServiceEdit({rootState}, service_id) {
        window.location.href = `${rootState.app.serviceAdminServer}/service/edit/${service_id}`
    },


    async makeNewContractType({rootState, commit, dispatch, state}) {
        wsseInterceptor(rootState.app)
        const url_reContract = `${rootState.app.hostServer}/api/transfer-to-preferred-contract`

        await axios.post(url_reContract, state.transitionSites)
            .then((response) => {
                commit('setTransitionSites', [])
                return response.data
            })
            .then((data) => {
                dispatch('fetchContract', data.new_contract_id)
            })
            .catch((err) => {
                console.log('Hiba az átszerződés létrehozásánál', err)
            })
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
