<template>
  <div class="ui segment contract-info-block">
    <div class="ui middle aligned divided list">

      <condition-list-item>
        <template v-slot:name>Szerződésszám:</template>
        <template v-slot:value>{{ contract ? contract.registrationNumber : '' }}</template>
      </condition-list-item>

      <condition-list-item>
        <template v-slot:name>Terméktípus</template>
        <template v-slot:value>

          <button class="ui button" @click="showPriceList">
            Árlista
          </button>


          <div class="ui yellow big label" style="color: black !important">
            {{ contract ? contract.productType.name : '' }}
          </div>
        </template>
      </condition-list-item>

      <condition-list-item>
        <template v-slot:name>Szolgáltató</template>
        <template v-slot:value>{{ contract ? contract.serviceCompany.name : '' }}</template>
      </condition-list-item>

      <condition-list-item>
        <template v-slot:name>Megrendelő</template>
        <template v-slot:value>{{ contract ? contract.customer.name : '' }}</template>
      </condition-list-item>


    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import CheckOneCondition from "@/components/contract/create/checkOneCondition";

export default {
  name: "ContractInfoComponent",
  components: {
    ConditionListItem: CheckOneCondition
  },
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,
    }),
    product() {
      const p = this.$store.state.updateContract.contract.productType
      return this.$store.state.app.products.find((el) => el.id === p)
    },
    serviceCompany() {
      const sc = this.$store.state.updateContract.contract.serviceCompany
      return this.$store.state.app.serviceCompanies.find((el) => el.id === sc)
    },


  },

  methods: {
    showPriceList() {
      this.$store.commit('updateContract/setShowPriceListModal', true);
    }
  }


}
</script>

<style scoped>

.contract-info-block {
  background-image: linear-gradient(to right, rgb(229, 255, 249), #f0f0f0);
}




</style>
