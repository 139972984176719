import {defineStore} from "pinia";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import wsseInterceptor from "@/_helper/wsseIntercentor";


export const useOpportunitiesStore = defineStore({
    id: 'opportunity',
    state: () => {
        return {
            opportunity: null,
            opportunities: []
        };
    },
    actions: {

        setOpportunity(opportunity) {
            this.opportunity = opportunity
        },

        setDelegatePerson(person){
          this.opportunity.account.customer.delegatePerson = person
        },
        setFinancialPerson(person){
            this.opportunity.account.customer.financialPerson = person
        },
        setTechnicalPerson(person){
            this.opportunity.account.customer.technicalPerson = person
        },

        setCustomerMainEmail(main_email){
            this.opportunity.account.customer.mainEmail = main_email
        },

        async fetchOpportunity(opportunity_id) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            wsseInterceptor(store_oro.host)

            const url_opportunity = store_oro.host + '/api/opportunity-info/' + opportunity_id

            axios.get(url_opportunity)
                .then(resp => {
                    this.setOpportunity(resp.data)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az opportunity lekérése sikertelen!'
                    }
                    store_errors.addError(error)
                });
        },
    },
    getters: {
        customer: (state) => {
            return state.opportunity ? state.opportunity.account.customer : null;
        },
        account: (state) => {
            return state.opportunity ? state.opportunity.account : null;
        },
        persons: (state) => {
            return state.opportunity ? state.opportunity.account.persons : [];
        },

    },
});

