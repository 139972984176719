<template>
  <Teleport to="body">

    <modal :show="show" :size="'modal-medium'"
           @close="$emit('onCloseClick')">
      <template #header>Telephely szerkesztése</template>
      <template #body>
        <identity-edit-form @close="$emit('onCloseClick')" />
      </template>
    </modal>

  </Teleport>
</template>

<script>
import Modal from "@/components/slots/modal.vue"

import IdentityEditForm from "@/components/contract/update/identity-edit-form.vue";

export default {
  name: "modal-edit-site",
  props: ['show'],
  components: {
    Modal,
    IdentityEditForm,
  },
  methods: {}

}
</script>

<style scoped>

</style>
