<template>
    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">

        <div class="ui three fields">

            <div class="field" :class="{error: 'payMethod' in errors}">
                <label>Fizetési mód</label>
                <div class="ui input">
                    <vee-field as="select" class="ui fluid dropdown" name="payMethod">
                        <option value="bank">Átutalás útján</option>
                        <option value="cash">Kp-ben</option>
                    </vee-field>
                </div>
                <error-message name="payMethod" class="error-message"/>
            </div>

            <div class="field" :class="{error: 'prompt' in errors}">
                <label>Fizetési határidő napokban</label>
                <div class="ui input">
                    <vee-field as="select" class="ui fluid dropdown" name="prompt">
                        <option value="8">8 nap</option>
                        <option value="15">15 nap</option>
                        <option value="28">28 nap</option>
                        <option value="45">45 nap</option>
                        <option value="60">60 nap</option>
                    </vee-field>
                </div>
                <error-message name="prompt" class="error-message"/>
            </div>

            <div class="field" :class="{error: 'billingPeriod' in errors}">
                <label>Gyakoriság</label>
                <div class="ui input">
                    <vee-field as="select" class="ui fluid dropdown" name="billingPeriod">
                        <option value="-1">Utólag 1 hónap</option>
                        <option value="1">Előre 1 hónap</option>
                        <option value="2">Előre 2 hónap</option>
                        <option value="3">Előre negyed év (3 hónap)</option>
                        <option value="6">Előre fél év (6 hónap)</option>
                        <option value="12">Előre egy év (12 hónap)</option>
                        <option value="24">Előre KETTŐ év (24 hónap)</option>
                        <option value="26">Előre HÁROM év (36 hónap)</option>
                    </vee-field>
                </div>
                <error-message name="billingPeriod" class="error-message"/>
            </div>
        </div>


        <div class="ui equal width grid">
            <div class="column">

                <button type="button" class="ui teal basic small button" @click="toggleIsTrial">
                    <span v-if="store_page.isTrial">Próba szerződés készül</span>
                    <span v-else>Normál szerződés</span>
                </button>
            </div>

            <div class="column">

                <div class="field" :class="{error: 'noticePeriodDay' in errors}">
                    <label>Felmondási idő napokban</label>
                    <div class="ui input">
                        <vee-field type="number" name="noticePeriodDay"/>
                    </div>
                    <error-message name="noticePeriodDay" class="error-message"/>
                </div>
            </div>

            <div class="column">
                <div class="field" :class="{error: 'reActivateFee' in errors}">
                    <label>Újra aktiválás költsége</label>
                    <div class="ui input">
                        <vee-field type="number" name="reActivateFee"/>
                    </div>
                    <error-message name="reActivateFee" class="error-message"/>
                </div>
            </div>
        </div>

        <div class="ui three fields">
            <div class="field" :class="{error: 'loyaltyName' in errors}">
                <label>Hűségidő</label>
                <div class="ui input">
                    <vee-field as="select" class="ui fluid dropdown" name="loyaltyName">
                        <option value="loyalty_year_1">1 év</option>
                        <option value="loyalty_year_2">2 év</option>
                        <option value="loyalty_year_3">3 év</option>
                    </vee-field>
                </div>
                <error-message name="loyaltyName" class="error-message"/>
            </div>

            <div class="field">
                <label>Számlafogadó e-mail címek</label>
                <FieldArray name="billRecipientEmails" key-path="id" v-slot="{ fields, push, remove }">
                    <div v-for="(field, idx) in fields" :key="field.key">
                        <error-message name="`billRecipientEmails[${idx}]`" class="error-message"/>
                        <div class="ui grid">
                            <div class="fourteen wide column">
                                <vee-field :name="`billRecipientEmails[${idx}]`" type="email"/>
                            </div>
                            <div class="two wide column">
                                <div class="two wide column" @click="remove(idx)">
                                    <i class="trash icon"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button"
                            class="ui basic green button newEmailBtn"
                            @click="push('' )">Új számlafogadó e-mail cím
                    </button>
                </FieldArray>
            </div>


        </div>

        <div class="ui segment">
            <div class="ui equal width column grid">
                <div class="column">
                    <div class="ui basic green button" @click="toPrev">Vissza</div>
                </div>

                <div class="column right aligned">
                    <button class="ui green button">Tovább</button>
                </div>
            </div>
        </div>

    </vee-form>
</template>

<script setup>
import {ErrorMessage, Field as VeeField, FieldArray, Form as VeeForm} from "vee-validate";
import * as yup from "yup";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {useContractStore} from "@/store-pinia/api/contract";


const store_page = usePageCreateContractStore();
const store_opportunity = useOpportunitiesStore();
const store_api_contract = useContractStore()


const schema = yup.object({
    payMethod: yup.string().required("Ki kell tölteni!"),
    prompt: yup.number("Csak szám lehet!").required("Ki kell tölteni!"),
    billingPeriod: yup.number("Csak szám lehet!").required("Ki kell tölteni!"),

    noticePeriodDay: yup.number("Csak szám lehet!").required("A felmondási időt meg kell adni!"),
    reActivateFee: yup.number("Csak szám lehet!").required("Meg kell adni!"),

    loyaltyName: yup.string().required("Ki kell tölteni!"),
    billRecipientEmails: yup.array().of(yup.string().required().email()),

})

const initialValues = {
    'payMethod': store_page.payMethod,
    'prompt': store_page.prompt,
    'billingPeriod': store_page.billingPeriod,

    'isTrial': store_page.isTrial,
    'noticePeriodDay': store_page.noticePeriodDay,
    'reActivateFee': store_page.reActivateFee,

    'loyaltyName': store_page.loyaltyName,
    'billRecipientEmails': store_page.billRecipientEmails,
}


function toggleIsTrial() {
    store_page.setIsTrial(!store_page.isTrial)
}


function toPrev() {
    store_page.setActiveStep(2)
}


function onSubmit(values) {


    store_page.setPayMethod(values.payMethod)
    store_page.setPrompt(values.prompt)
    store_page.setBillingPeriod(values.billingPeriod)

    store_page.setNoticePeriodDay(values.noticePeriodDay)
    store_page.setReActivateFee(values.reActivateFee)

    store_page.setLoyaltyName(values.loyaltyName)



    const send_data = {
        dateOfContract: store_page.dateOfContract,
        isTrial: store_page.isTrial,
        signed: store_page.signed,
        noticePeriodDay: store_page.noticePeriodDay,
        reActivateFee: store_page.noticePeriodDay,
        billingPeriod: parseInt(store_page.billingPeriod),
        payMethod: store_page.payMethod,
        prompt: store_page.prompt,
        dueOn: store_page.dueOn,
        quotation: store_page.quotation,
        productType: store_page.product.id,
        loyaltyName: store_page.loyaltyName,
        serviceCompany: store_page.serviceCompany,
        customer: store_opportunity.account.customer.id,
        delegatePerson: store_opportunity.account.customer.delegatePerson.id,
        financialPerson: store_opportunity.account.customer.financialPerson.id,
        technicalPerson: store_opportunity.account.customer.technicalPerson.id,
        mainEmail: store_opportunity.account.customer.mainEmail,
        billRecipientEmails: values.billRecipientEmails

    }

   // alert(JSON.stringify(send_data, null, 2))

    store_api_contract.createSimpleContract(send_data)

}


</script>

<style scoped>
.error-message {
    color: red;
}

.newEmailBtn {
    margin-top: 10px;
}
</style>
