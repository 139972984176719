import axios from 'axios'
import wsseInterceptor from "@/_helper/wsseIntercentor";

const state = () => ({
  opportunities: []
})

const getters = {}

const mutations = {
  setOpportunities (state, opportunities) {
    state.opportunities = opportunities
  },


}

const actions = {
  fetchOpportunities ({rootState, commit}) {
    wsseInterceptor(rootState.app)
    const url = rootState.app.hostServer + '/api/opportunities'

    axios.get(url)
      .then(resp => {
        commit('setOpportunities', resp.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
