import {createRouter, createWebHistory} from 'vue-router';
import * as pages from './pages.ts';
import QuotationCreatePageOld from "@/pages/quotation/create/QuotationCreatePage";
import CreateQuotationPage from "@/pages/createQuotationPage.vue";
import UpdateQuotationPage from "@/pages/updateQuotationPage.vue"

import ContractCreatePage from "@/pages/contract/create/ContractCreatePage";
import ContractUpdatePage from "@/pages/contract/update/ContractUpdatePage";
import ContractItemUpdatePage from "@/pages/contract/update/ContractItemUpdatePage";
import ApproveRequestPage from "@/pages/contract/approve-request/ApproveRequestPage";

import HomePage from "@/pages/homepage/HomePage";



const routes = [
    {
        path: '/',
        name: pages.PAGE_HOMEPAGE,
        component: HomePage,
    },
    {
        path: '/create-quotation/:opportunity_id',
        name: pages.PAGE_QUOTATION_CREATE,
        component: CreateQuotationPage,
    },
    {
        path: '/edit-quotation/:quotation_id',
        name: pages.PAGE_QUOTATION_EDIT,
        component: UpdateQuotationPage,
    },
    {
        path: '/quotation/create',
        name: pages.PAGE_QUOTATION_CREATE_OLD,
        component: QuotationCreatePageOld,
    },
    {
        path: '/contract/create/:opportunity_id',
        name: pages.PAGE_CONTRACT_CREATE,
        component: ContractCreatePage,
    },
    {
        path: '/contract/update/:contract_id',
        name: pages.PAGE_CONTRACT_UPDATE,
        component: ContractUpdatePage,
    },
    {
        path: '/contract/item-update/',
        name: pages.PAGE_CONTRACT_ITEM_UPDATE,
        component: ContractItemUpdatePage,
    },
    {
        path: '/approve-request/',
        name: pages.PAGE_APPROVE_REQUEST,
        component: ApproveRequestPage,
    },
    {
        path: '/:catchAll(.*)*',
        redirect: {name: pages.PAGE_HOMEPAGE}
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
