<template>
  <Teleport to="body">

    <modal :show="show" :size="'modal-medium'"
           @close="$emit('onCloseClick')">
      <template #header>Szerződési feltételek szerkesztése</template>
      <template #body>
        <conditions-edit-form @close="$emit('onCloseClick')"/>
      </template>
    </modal>

  </Teleport>
</template>

<script>
import Modal from "@/components/slots/modal.vue"

 import ConditionsEditForm from "@/components/contract/update/conditions-edit-form.vue";

export default {
  name: "modal-edit-conditions",
  props: ['show'],
  components: {
    Modal,
    ConditionsEditForm,
  },
  methods: {}

}
</script>

<style scoped>

</style>
