<template>
    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{ errors, values}">

        <div class="ui message">

            <FieldArray name="need_items" v-slot="{ fields, push, remove }">

                <div class="ui equal width grid">
                    <div class="column">
                        <h3>Árajánlatkészítés {{ store_page.product.name }} termékre</h3>
                    </div>
                    <div class=" right aligned column">
                        <button
                                class="ui basic green icon mini button"
                                type="button" @click="push({ priceListItem: '', quantity: 1} )">
                            <i class="plus green circle large icon"/>Új tétel
                        </button>
                    </div>
                </div>

                <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
                    <add-price-list-item-to-calculation :remove="remove" :idx="idx" :errors="errors"
                                                        @submit="onSubmit(values)"
                                                        :values="values"/>
                </div>

            </FieldArray>
        </div>

        <calculation-preview/>
        <step-b-actions/>
    </vee-form>


    <div class="ui message" v-if="false">

  <pre>
    {{ store_calculation.needs }}
  </pre>
    </div>

</template>

<script setup>

import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import {useCalculationStore} from "@/store-pinia/calculation";
import CalculationPreview from "@/components/calculation/calculation-preview.vue";
import StepBActions from "@/components/quotationCreate/step-b-actions.vue";
import {FieldArray, Form as VeeForm} from "vee-validate";
import * as yup from "yup";

import AddPriceListItemToCalculation from "@/components/calculation/add-price-list-item-to-calculation.vue"
import {onMounted} from "vue";
import {useGeneralPriceListStore} from "@/store-pinia/api/generalPriceList";


const store_page = useCreateQuotationPageStore();

const store_calculation = useCalculationStore();
const store_general_price_list = useGeneralPriceListStore();


onMounted(() => {
    store_calculation.setPriceList(store_general_price_list.priceList)
})


const schema = yup.object({
    need_items: yup.array().of(
        yup.object().shape(
            {
                priceListItem: yup.string().required('Megadása kötelező!'),
                quantity: yup.number().required('Megadása kötelező')
            })
    ),
})

const initialValues = {
    need_items: [
        {
            priceListItem: '',
            quantity: 1,
        }
    ],
}


function onSubmit(values) {
    store_page.setNeeds(values.need_items);
    store_calculation.setNeeds(values.need_items);
    store_calculation.calculate();
}

</script>

<style scoped>

</style>
