<template>

  <div class="ui equal width grid">
    <div class="column">
      <h3 class="ui header">Árlista</h3>
    </div>
    <div class="right aligned column">
      <button class="ui basic green button" @click="store_page.setShowModalCreatePriceList(true)">
        Új tétel hozzáadása az árlistához
      </button>
    </div>
  </div>


  <table class="ui celled table" v-if="store.quotation">
    <thead>
    <tr>
      <th>Tétel neve</th>
      <th class="right aligned">Egységár</th>
      <th class="center aligned">Típus</th>
      <th class="right aligned"></th>

    </tr>
    </thead>
    <tbody>

    <tr v-for="(price_list_item, index) in store.availableCosts" :key="index">
      <td data-label="Megnevezés">{{ price_list_item.name }}</td>
      <td data-label="Egységára" class="right aligned">{{ numberFormat(price_list_item.unitPrice, '0') }}</td>
      <td data-label="Típusa" class="center aligned ">
        <template v-if="price_list_item.categoryName === 'service_fee'">
          Szerviz költség
        </template>
        <template v-if="price_list_item.categoryName === 'install_cost'">
          Felszerelési költség
        </template>
        <template v-if="price_list_item.categoryName === 'monthly_fee'">
          Havidíj
        </template>
      </td>

      <td class="center aligned">
        <div class="ui animated basic teal mini button"
             @click="startAdding(price_list_item)"
             tabindex="0">
          <div class="visible content">
            <i class="plus teal icon"></i>
          </div>
          <div class="hidden content">
            Hozzáad
          </div>
        </div>


        <div class="ui animated basic red mini button"
             @click="startEdit(price_list_item)"
             tabindex="0">
          <div class="visible content">
            <i class="edit red icon"></i>
          </div>
          <div class="hidden content">
            Szerkeszt
          </div>
        </div>


      </td>
    </tr>

    </tbody>

  </table>

</template>

<script setup>

import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import {numberFormat} from "@/_helper/numberFormat";
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";

const store = useApiQuotationStore();

const store_page = useUpdateQuotationPageStore();

function startAdding(price_list_item) {
  store_page.setFocusPriceListElement(price_list_item)
  store_page.setShowModalAddToItems(true)
}


function startEdit(price_list_item) {

  store_page.setFocusPriceListElement(price_list_item)
  store_page.setShowModalEditPriceList(true)
}


</script>

<style scoped>

</style>