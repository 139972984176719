<template>
  <vee-form
      class="ui form"
      @submit="onSubmit"
      :initial-values="initialValues()">

    <div
        v-if="store.editedChange && store.editedChange.id === props.cost.priceChanges[props.cost.priceChanges.length-1].id">

      <div class="ui mini input">
        <vee-field type="text" placeholder="Új név" name="name"/>
      </div>


      <div class="ui mini input">
        <vee-field type="number" placeholder="Új ár" name="price"/>
      </div>

      <div class="ui buttons">
        <button type="submit" class="ui positive mini button" @click="onSubmit">Mentés</button>
        <div class="or" data-text="?"></div>
        <button class="ui negative mini  button"
                @click="store.setEditedChange(null)">Mégsem
        </button>
      </div>

    </div>
  </vee-form>
</template>

<script setup>
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {defineProps} from "vue";
import {Field as VeeField, Form as VeeForm} from 'vee-validate'

const props = defineProps(['cost', 'field']);
const store = useContractPriceListStore();


function initialValues() {
  return {
    name: props.cost.name,
    price: props.cost.priceChanges[props.cost.priceChanges.length - 1].price
  }
}

function onSubmit(values) {

  const send_data = {
    name: values.name,
    price: parseInt(values.price),
  }

  store.patchPriceListChange(props.cost.priceChanges[props.cost.priceChanges.length - 1].id, send_data)

}


</script>

<style scoped>
.form {
  margin: 15px 0;
}
</style>