<template>
  <div class="ui fluid steps">
    <div class="step" :class="{ active: activeStep===1 }" >
      <div class="content">
        <div class="icon_container">
          <i class="history big icon"/>
        </div>
        <div class="title">Opportunity</div>
        <div class="description">Opportunity, stb</div>
      </div>
    </div>

    <div class="step" :class="{ active: activeStep===2 }">
      <div class="content">
        <div class="icon_container">
          <i class="dollar big icon"/>
        </div>
        <div class="title">Árajánlat tételei</div>
        <div class="description">Mire van az ügyfélnek szüksége?</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'QuotationCreateStepHeader',
  computed: mapState({
    activeStep: state => state.createQuotation.activeStep
  })

}
</script>

<style scoped>
.icon_container {
  text-align: center;
  margin-bottom: 10px;
}
</style>
