import axios from "axios";
import wsseInterceptor from "@/_helper/wsseIntercentor";

const state = () => ({
    customer: {
        mainAddress: {
            postalCode: '',
            city: '',
            street: ''
        },
        postingAddress: {
            postalCode: '',
            city: '',
            street: ''
        },
        mainEmail: '',
        taxNumber: '',
        registrationNumber: '',
        name: '',

    },

})

const getters = {}

const mutations = {

    setCustomer(state, customer) {
        state.customer = customer
    },

}

const actions = {

    async createContactPerson({rootState}, data) {
        wsseInterceptor(rootState.app)
        const url_contactPersons = rootState.app.hostServer + '/api/contacts'

        const send_data = {
            firstName: data.firstName,
            lastName: data.lastName,
            primaryEmail: data.emails ? data.emails[0] : null,
            primaryPhone: data.phones ? data.phones[0] : null,
            accounts: data.accounts
        }

        return await axios.post(url_contactPersons, send_data)
            .then((response) => {
                return response.data
            })
    },


    async updateCustomer({rootState, commit}, customer) {
        wsseInterceptor(rootState.app)
        const url_customer = rootState.app.hostServer + '/api/admincustomers/' + customer.id;
        const send_customer_data = {...customer}
        delete send_customer_data.id

        await axios.patch(url_customer, send_customer_data)
            .then(resp => {
                commit('setCustomer', resp.data)
            })
    }



}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
