import {defineStore} from "pinia";

export const useNotificationsStore = defineStore({
    id: 'notifications',
    state: () => {
        return {
            notifications: []
        };
    },
    actions: {
        addNotification(notification) {
            this.notifications.push(notification)
        },
        clearNotifications() {
            this.notifications = []
        }
    },
    getters: {},
});
