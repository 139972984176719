<template>

    <div id="main" class="ui container">
        <h1 class="ui header">Árajánlat készítése</h1>
        <div class="ui segment">
            <step-header/>
        </div>
        <div class="ui segment">

            <step-a v-if="store_page.activeStep === 1"/>
            <step-b v-if="store_page.activeStep === 2"/>

        </div>
    </div>


</template>

<script setup>
import {onMounted} from "vue";
import {useRoute} from "vue-router";


import StepHeader from "@/components/quotationCreate/stepHeader.vue";
import StepA from "@/components/quotationCreate/step-a.vue"
import StepB from "@/components/quotationCreate/step-b.vue"
import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {useGeneralPriceListStore} from "@/store-pinia/api/generalPriceList";
import {useProductsStore} from "@/store-pinia/api/products";
import {useServiceCompanyStore} from "@/store-pinia/api/serviceCompany";


const store_page = useCreateQuotationPageStore();
const store_opportunity = useOpportunitiesStore();
const store_general_price_list = useGeneralPriceListStore();
const store_products = useProductsStore();
const store_service_company = useServiceCompanyStore();


onMounted(() => {
    const route = useRoute();
    store_page.setActiveStep(1);
    store_opportunity.fetchOpportunity(route.params.opportunity_id);
    store_general_price_list.fetchPriceList();
    store_products.fetchProducts();
    store_service_company.fetchServiceCompanies();
})


</script>

<style scoped>

</style>