<template>
  <div class="ui segment">
    <div class="ui container grid">
      <FieldArray name="needs" v-slot="{ fields, push, remove}">
        <div class="thirteen wide column">
          <h3 class="ui header">Telephely tétel</h3>
        </div>
        <div class="three wide column">
          <i class="calculator orange large icon" @click="calculate(fields)"/>
          <i class="plus green circle large icon" @click="push({ costType: '', quantity: 1} )"/>
        </div>
        <div class="sixteen wide column">
          <div v-for="(need, idx_needs) in fields" :key="need.key">
            <need-widget-component :remove="remove" :idx_needs="idx_needs"
                                   @calculate="calculate(fields)"/>
          </div>
        </div>
      </FieldArray>
    </div>
  </div>
</template>

<script>
import NeedWidgetComponent from "@/components/quotation/create/needWidgetComponent";
import {FieldArray} from 'vee-validate'
import {mapState} from "vuex";

export default {
  name: "NeedsSegment",
  props: ['needs', 'errors'],
  components: {
    NeedWidgetComponent,
    FieldArray
  },
  computed: {
    ...mapState({
      quotation: state => state.createQuotation
    })
  },
  methods: {
    calculate(fields) {
      this.$store.dispatch('createQuotation/setNeeds', fields);
    }
  }
}
</script>

<style scoped>

</style>
