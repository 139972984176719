<template>
  <div id="main" class="ui container">
    <h1 class="ui red header">Árajánlat készítése (Megszűnik!!!!)</h1>
    <div class="ui segment">
      <quotation-create-step-header/>
    </div>
    <div class="ui segment">
      <quotation-create-step-a v-if="activeStep === 1"/>
      <quotation-create-step-b v-if="activeStep === 2"/>

    </div>
  </div>

</template>


<script>

import QuotationCreateStepA from "@/components/quotation/create/stepA";
import QuotationCreateStepB from "@/components/quotation/create/stepB";
import QuotationCreateStepHeader from "@/components/quotation/create/stepHeader";
import {mapState} from "vuex";

export default {
  name: "QuotationCreatePage",
  components: {
    QuotationCreateStepHeader,
    QuotationCreateStepA,
    QuotationCreateStepB,

  },
  computed: mapState({
    activeStep: state => state.createQuotation.activeStep
  }),
  created(){
    const hostServerParams = this.$store.state.app.hostServerParams

    if (hostServerParams && hostServerParams.reason === 'QUOTATION_CREATE') {
      const values = hostServerParams.values;
      if (values && values.opportunity) {
        this.$store.dispatch('createQuotation/fetchOpportunity', values.opportunity)
      }
    } else {
      console.log('Nincs opportunity, ami alapján lehetne árajánlatot készíteni')
    }
  }
}
</script>

<style scoped>

</style>
