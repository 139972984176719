<template>
  <div class="ui segment">

    <div class="ui equal width grid">
      <div class="left aligned column">
        <button class="ui basic button">
          Vissza
        </button>
      </div>
      <div class="center aligned column">
        <div class="field">
          <div class="ui toggle checkbox">
            <input type="checkbox" v-model="store_page.checked">
            <label>Az adatokat ellenőriztem, az árajánlatot létrehozom!</label>
          </div>
        </div>
      </div>
      <div class="right aligned column">
        <button class="ui red button"
                :disabled="!store_page.checked || store_calculation.result.length === 0 || store_page.sending"
                @click="createQuotation">
          Az árajánlat létrehozása
        </button>
      </div>

    </div>

  </div>

</template>

<script setup>

import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import {useCalculationStore} from "@/store-pinia/calculation";
import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {useOroStore} from "@/store-pinia/oro";

const store_page = useCreateQuotationPageStore();
const store_opportunity = useOpportunitiesStore();
const store_calculation = useCalculationStore();
const store_api_quotation = useApiQuotationStore();
const store_oro = useOroStore();


async function createQuotation() {

  store_page.setSending(true);

  const data = {
    opportunity: parseInt(store_opportunity.opportunity.id),
    user: store_oro.user,
    serviceCompany: parseInt(store_page.serviceCompany.id),
    product: parseInt(store_page.product.id),
    validDays: 30,
    needItems: store_page.needs
  }

  await store_api_quotation.createQuotation(data)

   window.location.href = `${store_oro.host}/admin/simple-quotations`

}

</script>

<style scoped>

</style>