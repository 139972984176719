<template>
  <div class="ui segment conditions-block pointer">
    <div class="ui middle aligned divided list">


      <condition-item>
        <template v-slot:name>Szerződéskötési dátum</template>
        <template v-slot:value>{{ contract ? contract.dateOfContract : '' }}</template>
      </condition-item>

      <condition-item>
        <template v-slot:name>Próba szerződés?</template>
        <template v-slot:value>
          <span v-if="contract && contract.isTrial">Igen, próbaszerződés</span>
          <span v-else>Nem</span>
        </template>
      </condition-item>

      <condition-item>
        <template v-slot:name>Fizetési mód</template>
        <template v-slot:value>
          <span v-if="contract && contract.payMethod === 'bank'">Átulalással</span>
          <span v-else-if="contract && contract.payMethod === 'cash'">Készpénzben</span>
          <span v-else-if="contract">Más módon</span>
        </template>
      </condition-item>

      <condition-item>
        <template v-slot:name>Fizetési határidő, napokban</template>
        <template v-slot:value>{{ contract && contract.prompt }} nap</template>
      </condition-item>

      <condition-item>
        <template v-slot:name>Gyakoriság</template>
        <template v-slot:value>
          <span v-if="contract && contract.billingPeriod === -1">Utólag</span>
          <span v-else-if="contract">Előre, {{ contract.billingPeriod }} hónap</span>
        </template>
      </condition-item>

      <!--      <condition-item>-->
      <!--        <template v-slot:name>Számlafogadó e-mail címek</template>-->
      <!--        <template v-slot:value>{{ emails }}</template>-->
      <!--      </condition-item>-->


      <condition-item>
        <template v-slot:name>Felmondási idő, napokban</template>
        <template v-slot:value>{{ contract && contract.noticePeriodDay }} nap</template>
      </condition-item>

      <condition-item>
        <template v-slot:name>Újraaktiválás költsége</template>
        <template v-slot:value>{{ contract && contract.reActivateFee.toLocaleString() }} Ft</template>
      </condition-item>


      <condition-item>
        <template v-slot:name>Hűségidő</template>
        <template v-slot:value>{{ loyaltyName }}</template>
      </condition-item>

      <!--      <condition-item>-->
      <!--        <template v-slot:name>Üzletkötő</template>-->
      <!--        <template v-slot:value>__üzletkötő</template>-->
      <!--      </condition-item>-->


    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ConditionItem from "@/components/contract/create/checkOneCondition";

export default {
  name: 'ContractConditions',
  components: {
    ConditionItem
  },
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,
    }),

    loyaltyName() {
      let name = '???';
      if (!this.contract) {
        name = '';
      } else if (this.contract.loyalty === 'loyalty_year_1') {
        name = '1 év'
      } else if (this.contract.loyalty === 'loyalty_year_2') {
        name = '2 év'
      } else if (this.contract.loyalty === 'loyalty_year_3') {
        name = '3 év'
      }
      return name
    },

  },

}
</script>

<style scoped>
.conditions-block {
  background-image: linear-gradient(to right, rgb(229, 255, 249), #f0f0f0);
}

.pointer {
  cursor: pointer;
}
</style>
