<template>

  <Teleport to="body">

    <modal :show="show" :size="'modal-large'"
           @close="onCloseModal">
      <template #header>Egyedi árlista ...</template>
      <template #body>

        <financial-contact-person/>


        <vee-form class="ui form"
                  @submit="onSubmit"
                  v-slot="{fields, errors}">

            <price-list-actions/>


            <price-list-monthly-fees :fields="fields" :errors="errors"/>

            <hr class="divider">

            <price-list-install-costs :fields="fields" :errors="errors"/>

            <hr class="divider">
            <price-list-service-costs :fields="fields" :errors="errors"/>


        </vee-form>

      </template>
    </modal>

  </Teleport>
</template>

<script>
import {mapState} from "vuex";
import Modal from "@/components/slots/modal.vue"
import PriceListMonthlyFees from "@/components/contract/update/contract-priceList/price-list-monthly-fees.vue";
import PriceListInstallCosts from "@/components/contract/update/contract-priceList/price-list-install-costs.vue";
import PriceListServiceCosts from "@/components/contract/update/contract-priceList/price-list-service-costs.vue";
import FinancialContactPerson
  from "@/components/contract/update/contract-priceList/price-list-financial-contact-person.vue";

import PriceListActions from "@/components/contract/update/contract-priceList/price-list-actions.vue";
import {Form as VeeForm} from 'vee-validate'
import * as yup from "yup";

export default {
  name: "modal-price-list",


  components: {
    PriceListActions,
    FinancialContactPerson,
    PriceListServiceCosts,
    PriceListMonthlyFees,
    PriceListInstallCosts,
    VeeForm,

    Modal
  },
  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowPriceListModal', false)
    },
    onSubmit(values) {
      alert('Küldés meghívásra került ' + JSON.stringify(values, null, 2))
    },

    data() {

      return {
        schema: yup.object({
          dateOfChange: yup.date().required("Az árváltozás dátumát meg kell admin"),
          contractPrices: yup.array().of(
              // yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
              yup.object().shape({
                    id: yup.string().required('Meg kell adni!'),
                    newPrice: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                  }
              )
          )
        }),

      }

    }


  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showPriceListModal,
    })

  }

}
</script>


<style scoped>
.divider {
  margin: 2rem 0;
}
</style>
