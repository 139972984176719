<template>
  <div class="field">
    <label>Telephely keresése</label>
    <div class="ui sixteen width grid">
      <div class="fourteen wide column">
        <div class="ui icon input">
          <i class="search icon"/>
                    <input
                        type="text"
                        v-model="store.searchSite"
                        @keyup="store.fetchSites()"
                        placeholder="Telephely keresése"/>
        </div>
      </div>

      <div class="two wide column">
                <i class="trash red  icon pointer"
                   @click="store.setSearchSite('')"/>
      </div>
    </div>
  </div>

</template>

<script setup>
import {useReContractSiteStore} from "@/store-pinia/reContractSite";

const store = useReContractSiteStore();


</script>

<style scoped>

</style>