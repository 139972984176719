<template>
  <div class="ui segment">

    <div class="ui grid ">
      <div class="row">
        <div class="eight wide column">
          <h4 class="ui header">Tételek</h4>
        </div>
        <div class="four wide right aligned column">
          <button class="ui red basic small button " style="margin-bottom: 1rem"
                  @click.stop="openCostsDetailingModal(site)">
            <i class="tasks red icon"></i>
            Kibontás
          </button>
        </div>

        <div class="four wide right aligned column">
          <button class="ui basic green small button " style="margin-bottom: 1rem" @click.stop="addNewCost(site)">
            <i class="plus green icon"></i>
            Új tétel hozzáadása
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="sixteen wide column">
        <div class="ui celled list">

          <div class="item">
            <cost-list-header/>
          </div>

          <div class="item" v-for="cost in costs" :key="cost.id">
            <cost-list-row :cost="cost"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useCostDetailingStore} from "@/store-pinia/costsDetailing";
import {useCostsStore} from "@/store-pinia/api/costs";

import CostListHeader from "@/components/contract/update/accordion-content-cost-row-header.vue"
import CostListRow from "@/components/contract/update/accordion-content-cost-row.vue"


export default {
  name: "accordion-content-column-site-costs-list-component",
  props: ['site', 'costs'],
  components: {
    CostListHeader,
    CostListRow
  },
  methods: {

    addNewCost(site) {
      const store_costs = useCostsStore();
      store_costs.setSite(site);

      this.$store.commit('updateContract/setEditedSite', site)
      this.$store.commit('updateContract/setShowAddCostModal', true)

    },

    openCostsDetailingModal(site) {
      const store_cost_detailing = useCostDetailingStore();
      store_cost_detailing.setSite(site);
      this.$store.commit('updateContract/setShowCostsDetailingModal', true)
    },

  }
}
</script>

<style scoped>
.pointerCursor {
  cursor: pointer;
}


</style>
