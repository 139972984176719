import {defineStore} from "pinia";

export const useCreateQuotationPageStore = defineStore({
    id: 'createQuotationPage',
    state: () => {
        return {
            sending: false,
            success: false,
            product: null,
            serviceCompany: null,
            activeStep: 1,
            checked: false,
            needs: [],
        };
    },
    actions: {

        setSending(sending_value) {
            this.sending = sending_value
        },
        setSuccess() {
            this.success = true
        },
        setProduct(product) {
            this.product = product
        },
        setServiceCompany(service_company) {
            this.serviceCompany = service_company
        },
        setActiveStep(step) {
            this.activeStep = step
        },
        setChecked(is_checked) {
            this.checked = is_checked
        },
        setNeeds(needs_arr) {
            this.needs = needs_arr
        },


    },
    getters: {},
});

