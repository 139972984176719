import {defineStore} from "pinia";
import axios from "axios";
import wsseInterceptor from "@/_helper/wsseIntercentor";
import {useErrorsStore} from "../errors";
import {useOroStore} from "@/store-pinia/oro";


const url_service_company = '/api/adminservicecompanies'

export const useServiceCompanyStore = defineStore({
    id: 'serviceCompany',
    state: () => {
        return {
            serviceCompany: null,
            serviceCompanies: []
        };
    },

    actions: {
        setServiceCompany(service_company) {
            this.serviceCompany = service_company
        },
        setServiceCompanies(service_company_arr) {
            this.serviceCompanies = service_company_arr
        },

        async fetchServiceCompanies() {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            const url = `${store_oro.host}${url_service_company}`;

            wsseInterceptor(store_oro.host)

            await axios.get(url)
                .then((response) => {
                    this.setServiceCompanies(response.data)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A szolgáltató cégek elérése sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },

    },

    getters: {},
});
