<template>

  <cost-header :costName="'Felszerelési költségek'"/>

  <div class="ui container grid">
    <div class="sixteen wide column">
      <div class="ui divided list">
        <div v-for="(cost) in store.installCosts" :key="cost.id" class="item">
          <price-list-item-row :cost="cost" :fields="props.fields"/>
        </div>
      </div>
    </div>
  </div>

  <div class="ui sixteen width grid">
    <template v-if="!store.showNew.install_cost">
      <div class="sixteen wide column">
        <button class="ui green basic mini icon button"
                @click="store.setShowNewValue('install_cost', true)">
          <i class="plus green icon"></i>
          Új felszerelési költség hozzáadása
        </button>
      </div>
    </template>

    <template v-else>
      <create-new-price :categoryName="'install_cost'"/>
    </template>
  </div>


</template>

<script setup>
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import CostHeader from "@/components/contract/update/contract-priceList/cost-header.vue";

import CreateNewPrice from "@/components/contract/update/contract-priceList/create-new-price.vue";
import PriceListItemRow from "@/components/contract/update/contract-priceList/price-list-item-row.vue";
import {defineProps} from "vue";

const store = useContractPriceListStore()
const props = defineProps(['fields']);

</script>

<style scoped>

</style>