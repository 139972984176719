import axios from 'axios'
import router from '@/router'
import wsseInterceptor from "@/_helper/wsseIntercentor";
import {useOroStore} from "@/store-pinia/oro";


const state = () => ({
    products: [],
    serviceCompanies: [],
    salesMans: [],
    apiKey: null,
    user: null,
    currentUser: null,
    hostServer: null,
    isAuthenticated: false,
    hostServerParams: null,
    navigate: null,
    serviceAdminServer: null,
    serviceAdminApiToken: null,
    preferredProductTypeId: null
})

const getters = {}

const mutations = {
    setProducts(state, products) {
        state.products = products
    },
    setServiceCompanies(state, serviceCompanies) {
        state.serviceCompanies = serviceCompanies
    },
    setSalesMans(state, salesMans) {
        state.salesMans = salesMans
    },
    setApiKey(state, apiKey) {
        state.apiKey = apiKey
    },
    setUser(state, user) {
        state.user = user
    },
    setHostServer(state, hostServer) {
        state.hostServer = hostServer
    },
    setIsAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated
    },
    setHostServerParams(state, params) {
        state.hostServerParams = params
    },
    setCurrentUser(state, currentUser) {
        state.currentUser = currentUser
    },
    setNavigate(state, navigate) {
        state.navigate = navigate
    },
    setServiceAdminServer(state, server_name) {
        state.serviceAdminServer = server_name
    },
    setServiceAdminApiToken(state, api_token) {
        state.serviceAdminApiToken = api_token
    },
    setPreferredProductTypeId(state, preferred_product_type_id) {
        state.preferredProductTypeId = preferred_product_type_id
    }

}

const actions = {

    auth({dispatch}, encodedCredentials) {
        if (encodedCredentials) {
            try {
                const decodedData = atob(encodedCredentials)
                const decodedObject = JSON.parse(decodedData)

                if (decodedObject.baseURL && decodedObject.userName && decodedObject.token) {
                    dispatch('login', decodedObject)
                }
            } catch (error) {

                //   vuexContext.dispatch('logout')
            }
        } else {
            //console.log('nincs mit ellenőrizni ezért kilépünk')

        }
    },

    fetchProducts({commit, state}) {
        wsseInterceptor(state)
        const url = state.hostServer + '/api/adminproducttypeentities'

        axios.get(url)
            .then(resp => {
                commit('setProducts', resp.data)
            })

    },

    fetchServiceCompanies({commit, state}) {
        wsseInterceptor(state)
        const url = state.hostServer + '/api/adminservicecompanies'

        axios.get(url)
            .then(resp => {
                commit('setServiceCompanies', resp.data)
            })
    },

    fetchCurrentUser({commit, state}) {
        wsseInterceptor(state)
        const url = state.hostServer + '/api/userprofile'

        axios.get(url)
            .then(resp => {
                commit('setCurrentUser', resp.data)
            })
    },

    async login({commit, dispatch, state}, credentials) {
        const store_oro = useOroStore();

        commit('setUser', credentials.userName)
        commit('setHostServer', credentials.baseURL)
        commit('setApiKey', credentials.token)
        commit('setIsAuthenticated', true)
        commit('setHostServerParams', credentials.params)
        commit('setNavigate', credentials.navigate)
        commit('setServiceAdminServer', credentials.serviceAdminHost)
        commit('setServiceAdminApiToken', credentials.serviceAdminApiToken)
        commit('setPreferredProductTypeId', credentials.preferred_product_type_id)


        await dispatch('fetchProducts')
        await dispatch('fetchServiceCompanies')
        await dispatch('fetchCurrentUser')


        store_oro.setHost(credentials.baseURL);
        store_oro.setUser(credentials.userName);
        store_oro.setApiKey(credentials.token);


        await router.push({path: state.navigate.to})

    },

    logout({commit}) {
        commit('setUser', null)
        commit('setHostServer', null)
        commit('setApiKey', null)
        commit('setIsAuthenticated', false)
        commit('setProducts', [])
        commit('setServiceCompanies', [])
        router.push({path: '/'})
    },


    async returnToCRM({state}) {
        window.location.href = state.navigate.return
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
