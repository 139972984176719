<template>
  <div style="margin-top: 1rem">

    <div class="field">
      <label>Átszerződési dátum</label>
      <div class="ui sixteen width grid">
        <div class="fourteen wide column">
          <div class="ui icon input">
            <input
                type="date"
                v-model="store.reContractDate"
                placeholder="Új telephely indulási dátuma"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {useReContractSiteStore} from "@/store-pinia/reContractSite";

const store = useReContractSiteStore();


</script>

<style scoped>

</style>