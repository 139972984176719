<template>
  <vee-form class="ui form"
            @submit="onSubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{errors}">

    <div class="ui segment">

      <div v-if="products.length === 0">
        <waiting-component/>
      </div>

      <div class="ui grid" v-else>
        <div class="sixteen wide column">
          <div class="ui container segment">
            <h3 class="ui header">Opportunity</h3>
            <div class="ui middle aligned divided list">
              <div class="item">
                <div class="content">
                  <div class="ui equal width grid">
                    <div class="column header">Cégnév</div>
                    <div class="column right aligned">{{ account ? account.name : '' }}</div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="content">
                  <div class="ui equal width grid">
                    <div class="column header">Kapcsolattartó neve</div>
                    <div class="column right aligned">{{ contact ? contact.lastName : '' }}
                      {{ contact ? contact.firstName : '' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="sixteen wide column">
          <div class="ui container segment">
            <h3 class="ui header">A terméktípus</h3>


            <div class="field" :class="{error: 'productType' in errors}">
              <div class="ui input">
                <vee-field as="select" class="ui fluid dropdown" name="productType">
                  <option value=""></option>
                  <option v-for="product in products" :value="product.id" :key="product.id">{{ product.name }}</option>
                </vee-field>
              </div>
              <error-message name="productType" class="error-message"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui container  segment">
      <div class="ui equal width grid">
        <div class="column right aligned">
          <button class="ui green button" :disabled="products.length === 0">Tovább</button>
        </div>
      </div>
    </div>

  </vee-form>
</template>

<script>

import {
  Form,
  Field,
  ErrorMessage
} from 'vee-validate'

import {mapState} from "vuex";
import * as yup from "yup";
import WaitingComponent from "@/components/waitingComponent";

export default {
  name: 'QuotationCreateStepA',
  components: {
    VeeForm: Form,
    VeeField: Field,
    ErrorMessage,
    WaitingComponent
  },
  data() {
    return {
      schema: yup.object({
        productType: yup.string().required("A termék típusát meg kell adni!")
      }),
      initialValues: {
        productType: this.$store.state.createQuotation.productType,
      }
    }
  },
  computed: mapState({
    products: state => state.app.products,
    opportunity: state => state.createQuotation.opportunity,
    account: state => state.createQuotation.account,
    contact: state => state.createQuotation.contact
  }),





  methods: {
    onSubmit(values) {
      this.$store.dispatch('createQuotation/setProductType', values.productType)
      this.$store.commit('createQuotation/setActiveStep', 2)
    },
  }

}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
