import {defineStore} from "pinia";
import wsseInterceptor from "@/_helper/wsseIntercentor";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import {useNotificationsStore} from "@/store-pinia/notifications";
import {useCalculationStore} from "@/store-pinia/calculation";


import vuex_store from '@/store'


export const useSitesStore = defineStore({
    id: 'site',
    state: () => {
        return {
            priceList: [],
            sending: false,
            fetching: false,
            site: null,
            sites: [],

            createdSite: null,
            newCosts: []


        };
    },
    actions: {
        setPriceList() {
            const store_calculation = useCalculationStore()
            this.priceList = store_calculation.priceList;
        },
        setSending(is_sending) {
            this.sending = is_sending
        },
        setFetching(is_fetching) {
            this.fetching = is_fetching
        },
        setSite(site) {
            this.site = site
        },
        setSites(sites_arr) {
            this.sites = sites_arr
        },

        setCreatedSite(created_site) {
            this.createdSite = created_site
        },
        setNewCosts(new_costs_arr) {
            this.newCosts = new_costs_arr
        },


        async createSite(contract_id, site_data) {


            const store_oro = useOroStore();
            const store_errors = useErrorsStore();
            const store_notifications = useNotificationsStore();

            wsseInterceptor(store_oro.host)
            const url = `${store_oro.host}/api/create-site/${contract_id}`


            this.setSending(true)


            await axios.post(url, site_data)
                .then((resp) => {
                    this.setCreatedSite(resp.data)
                })
                .then(() => {
                    store_notifications.addNotification('A telephely létrehozása sikeres');
                })
                .then(() => {

                    this.refreshContract(contract_id)
                })
                .catch(err => {
                    const error = {
                        //code: err.response.status,
                        message: err.message,
                        message_public: 'A telephely létrehozása sikertelen volt!'
                    }
                    store_errors.addError(error)
                })

            this.setSending(false)
        },


        async refreshContract(contract_id) {
            vuex_store.commit('updateContract/setShowNewSiteModal', false, {root: true});
            await vuex_store.dispatch('updateContract/fetchContract', contract_id, {root: true})
        }

    },
    getters: {},
});

