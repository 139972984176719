<template>

  <div class="title"
       :class="{
          active: index === this.$store.state.updateContract.activeAccordionIndex,
          active_accordion: index === this.$store.state.updateContract.activeAccordionIndex
     }"
       @click="toggleActive(index)">

    <div class="ui sixteen width grid">


      <div class="five wide column">
        <i class="dropdown icon"></i>
        {{ site.nameAtContract }} {{ site.address.city }}, {{ site.address.street }}
      </div>


      <div class="four wide center aligned column">
        <div class="ui equal width grid">
          <div class="right aligned column">
            {{ site.priceOneTime.toLocaleString() }} Ft
          </div>

          <div class="right aligned column">
            <span class="monthly-fee">
              {{ site.priceMonthly.toLocaleString() }} Ft/hó
            </span>
          </div>
        </div>
      </div>


      <div class="four wide column">

        <div v-if="site.status==='new' " class="ui tiny grey label">
          {{ siteStatusName(site.status) }}
        </div>

        <div v-if="site.status==='active' " class="ui tiny green label">
          {{ siteStatusName(site.status) }}: {{ site.activateDate }}
        </div>

        <div v-if="site.status==='terminated' " class="ui tiny red label">
          {{ siteStatusName(site.status) }}: {{ site.activateDate }} - {{ site.terminateDate }}
        </div>

      </div>


      <div class="two wide column">

        <i v-if="site.service"
           class="thumbs up outline green icon pointerCursor"
           @click.stop="toServiceEdit(site)"></i>


        <i v-if="site.status === 'new'"
           class="trash icon pointerCursor"
           @click.stop="deleteNewSite(site)"></i>

        <i v-if="site.status === 'active' && !site.terminateRequested"
           class="thumbs down outline red icon pointerCursor"
           @click.stop="siteTerminateRequest(site)"></i>

        <i class="edit icon pointerCursor"
           @click.stop="editSite(site)"></i>

      </div>

    </div>
  </div>
</template>

<script>
import {siteStatusName} from "@/_helper/siteStatusName";
import {mapState} from "vuex";

export default {
  name: "accordion-title",
  props: ['site', 'index'],
  emits: ['openEditSiteModal'],
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,
      preferredProductTypeId: state => state.app.preferredProductTypeId
    })
  },
  methods: {
    toggleActive(index) {
      if (this.$store.state.updateContract.activeAccordionIndex === index) {
        this.$store.commit('updateContract/setActiveAccordionIndex', -1)
      } else {
        this.$store.commit('updateContract/setActiveAccordionIndex', index)
      }
    },
    deleteNewSite(site) {
      this.$store.dispatch('updateContract/deleteSite', site)
    },
    siteTerminateRequest(site) {
      this.$store.commit('updateContract/setShowSiteTerminateRequestModal', true)
      this.$store.commit('updateContract/setEditedSite', site)

    },
    editSite(site) {
      this.$store.commit('updateContract/setEditedSite', site);
      this.$emit('openEditSiteModal')
    },
    onSendApproveRequest() {
      this.$router.push('/approve-request')
    },
    approveRequestSite(site) {
      this.$store.commit('approve/setApproveRequestInfo', {site})
      this.$router.push('/approve-request')
    },

    toServiceEdit(site) {
      this.$store.dispatch('updateContract/toServiceEdit', site.service.id)
    },
    siteStatusName(status_name) {
      return siteStatusName(status_name)
    },


  },

}
</script>

<style>
.terminate-request {
  color: red
}

.active_accordion {
  background-image: linear-gradient(to right, #d2f1a7, #c4d9dc) !important;
}

.monthly-fee {
  color: red;
  font-weight: bolder;
  font-style: italic;
}

</style>
