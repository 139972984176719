<template>
  <vee-form class="ui form"
            @submit="onSubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{errors}">

    <div class="ui container  segment">
      <div class="ui  stackable grid">
        <div class="sixteen wide column">
          <h3 class="ui header">Az ügyfél ezeket kéri</h3>
          <needs-segment :errors="errors" :needs="needs"/>
        </div>


        <div class="sixteen wide column">
          <h3 class="ui header">Ajánlatunk az ügyfél igényére</h3>
          <prices-segment/>
        </div>
      </div>
    </div>

    <waiting-component v-if="sending"/>

    <div class="ui container segment">
      <div class="ui sixteen width container grid">
        <div class="four wide column">
          <div class="ui basic green button"
               :class="{disabled: sending }"
               @click="toPrev">Vissza
          </div>
        </div>


        <div class="eight wide column center aligned">
          <div class="field">
            <div class="ui toggle checkbox">
              <input type="checkbox" :name="iChecked" v-model="iChecked">
              <label>Az adatokat ellenőriztem, az árajánlatot létrehozom!</label>
            </div>
          </div>
        </div>


        <div class="four wide column right aligned">
          <button class="ui red button" :class="{disabled: !iChecked || sending }">Az árajánlat létrehozása</button>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import NeedsSegment from "@/components/quotation/create/needsSegment";
import PricesSegment from "@/components/quotation/create/pricesSegment";
import {Form} from 'vee-validate'
import * as yup from "yup";
import WaitingComponent from "@/components/waitingComponent";

export default {
  name: 'QuotationCreateStepB',
  components: {
    VeeForm: Form,
    NeedsSegment,
    PricesSegment,
    WaitingComponent

  },
  data() {
    return {
      iChecked: false,
      sending: false,
      schema: yup.object({
        needs: yup.array()
            .of(
                yup.object().shape({
                  costType: yup.string().required('Meg kell adni!'),
                  quantity: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                })
            )
      }),
      initialValues: {
        'needs': this.$store.state.createQuotation.needs
      }
    }

  },
  methods: {
    toPrev() {
      this.$store.commit('createQuotation/setActiveStep', 1)
    },
    onSubmit(values) {
      this.sending = true;
      this.$store.dispatch('createQuotation/createQuotation', values)
    },
  },
  computed: {
    needs() {
      return this.$store.state.createQuotation.needs
    },

  },

}
</script>

<style scoped>

</style>
