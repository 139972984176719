<template>
  <div style="margin-bottom: 2rem;">

    <h5 class="ui header">
      Pénzügyi kapcsolattartó:
    </h5>

    <div class="ui sixteen width grid">
      <div class="four wide column">

        <span> {{ person.name }} </span>
      </div>

      <div class="four wide column">
        <div class="ui divided list">

          <div class="item" v-for="(email, index) in person.emails" :key="index">
            {{ email }} &nbsp;
          </div>
        </div>
      </div>

      <div class="four wide column">
        <div class="item">

          <div class="item" v-for="(phone, index) in person.phones" :key="index">
            {{ phone }} &nbsp;
          </div>
        </div>
      </div>


    </div>
  </div>

</template>

<script>

import {useStore} from 'vuex';
import {computed} from 'vue';

export default {
  setup() {
    const store = useStore()
    return {
      person: computed(() => store.state.updateContract.contract ? store.state.updateContract.contract.financialPerson : {}),
    }
  }
}

</script>


<style scoped>

</style>