import {defineStore} from "pinia";
import wsseInterceptor from "@/_helper/wsseIntercentor";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";

import router from "@/router";


const url_contract_info = '/api/contract-info/';


export const useContractStore = defineStore({
    id: 'contract',
    state: () => {
        return {
            sending: false,
            fetching: false,
            contract: null,
            contractId: null
        };
    },
    actions: {
        setSending(is_sending) {
            this.sending = is_sending
        },
        setFetching(is_fetching) {
            this.fetching = is_fetching
        },
        setContract(contract) {
            this.contract = contract
        },
        setContractId(contract_id) {
            this.contractId = contract_id
        },



        async fetchContract(contract_id) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setFetching(true)
            wsseInterceptor(store_oro.host)
            const url_contract = store_oro.host + url_contract_info + contract_id

            await axios.get(url_contract)
                .then(resp => {
                    this.setContract(resp.data);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A szerződés lekérése sikertelen!'
                    }
                    store_errors.addError(error)
                })

            this.setFetching(false)
        },



        async createSimpleContract(contract_data) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setFetching(true)
            wsseInterceptor(store_oro.host)
            const url = store_oro.host + '/api/create-simple-contract'

            await axios.post(url, contract_data)
                .then(async (resp) => {
                    await this.fetchContract(resp.data.contract_id);
                })
                .then(() => {
                    router.push(`/contract/update/${this.contract.id}`)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A szerződés létrehozása sikertelen!'
                    }
                    store_errors.addError(error)
                })

            this.setFetching(false)
        },







    },
    getters: {},
});

