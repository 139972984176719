<template>

    <div class="right aligned column" v-if="!store.contract.priceList_validFromDate">

      <vee-form class="ui form"
                @submit="makeSubmit"
                :initial-values="initialValues"
                :validation-schema="schema">
        <div class="ui mini input">
          <vee-field type="date"
                     placeholder="Érvényesség kezdete"
                     name="startDate"/>
        </div>

        <error-message name="startDate" class="error-message"/>

        <button
            class="ui basic mini green button">OK
        </button>
      </vee-form>

    </div>

    <div v-else v-for="date in store.contract.priceList_changeDates" class="right aligned column" :key="date">
      {{ date }}
    </div>




</template>

<script setup>

import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import * as yup from "yup";

const store = useContractPriceListStore();

const schema = yup.object({
  startDate: yup.date('Érvényes dátumra van szükség').required('Az érvényesség kezdetét meg kell admin'),
});


const initialValues = {
  startDate: store.request.startingFromDate
}


function makeSubmit(values) {

  const send_data = {
    validFromDate: values.startDate
  }

  store.callContractPricesStartingFromDate(send_data);

}


</script>

<style scoped>
.error-message {
  color: red;
}



</style>