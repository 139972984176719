import {defineStore} from "pinia";


export const useOroStore = defineStore({
    id: 'oro',
    state: () => {
        return {
            host: null,
            user: null,
            apiKey: null
        };
    },

    actions: {
        setHost(host) {
            this.host = host
        },
        setUser(user) {
            this.user = user;
        },
        setApiKey(api_key) {
            this.apiKey = api_key;
        },
    },

    getters: {},
});
