<template>

    <div class="ui sixteen width grid">
        <div class="four wide column" :class="{deprecated: props.cost.deprecated}">
            {{ props.cost.name }}
        </div>

        <div class="two wide column">
            <span v-if="props.cost.source===2">saját felvitel</span>
        </div>

        <div class="two wide column">
            <span v-if="props.cost.used==='no'">Nincs használva</span>
        </div>


        <div v-if="!store.editedChange" class="eight wide right aligned column">
            <price-history :cost="props.cost" :fields="props.fields"/>
        </div>
        <div v-else class="eight wide right aligned column">
            <edit-current-price :cost="props.cost"/>
        </div>
    </div>

</template>

<script setup>
import PriceHistory from "@/components/contract/update/contract-priceList/price-history.vue";
import EditCurrentPrice from "@/components/contract/update/contract-priceList/edit-current-price.vue";
import {defineProps} from "vue";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";


const props = defineProps(['cost', 'fields']);
const store = useContractPriceListStore();


</script>

<style scoped>
.deprecated {
    color: red;
    text-decoration: line-through;
}

</style>