<template>
    <div class="ui grid ">
        <div class="eleven wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field as="select"
                               class="ui fluid dropdown"
                               :class="{error: `need_items[${props.idx}].priceListItem` in props.errors}"
                               :name="`need_items[${props.idx}].priceListItem`"
                               @change="$emit('submit')"
                               @blur="$emit('submit')">
                        <option value=""></option>
                        <option v-for="priceList_item in store_calculation.availableCosts(store_page.product.id)"
                                :value="priceList_item.id"
                                :key="priceList_item.id">
                            {{ priceList_item.name }} (Egységár: {{ numberFormat(priceList_item.unitPrice, '---') }} Ft/db )
                        </option>
                    </vee-field>
                </div>

                <error-message :name="`need_items[${props.idx}].priceListItem`" class="error-message"/>
            </div>
        </div>

        <div class="four wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field
                            :class="{error: `need_items[${props.idx}].quantity` in props.errors}"
                            :name="`need_items[${props.idx}].quantity`"
                            @change="$emit('submit')"
                            @blur="$emit('submit')">
                    </vee-field>
                </div>
                <error-message :name="`need_items[${props.idx}].quantity`" class="error-message"/>
            </div>
        </div>

        <div class="one wide center aligned column">
            <i class="trash red  icon" @click="removeItem(props.idx)"/>
        </div>
    </div>
</template>

<script setup>
import {ErrorMessage, Field as VeeField} from "vee-validate";

import {defineProps} from "vue";
import {useCalculationStore} from "@/store-pinia/calculation";
import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import {numberFormat} from "@/_helper/numberFormat";


const props = defineProps(['remove', 'idx', 'errors', 'values']);
const store_calculation = useCalculationStore();
const store_page = useCreateQuotationPageStore();


function removeItem(idx) {
    console.log('will remove:', idx)
    props.remove(idx)
    store_calculation.calculate()
}


</script>

<style scoped>
.error-message {
    color: red
}

</style>