<template>
  <div id="main" class="ui container">
    <h1 class="ui header">Árajánlat szerkesztése</h1>

    <error-component/>

    <div class="ui segment">
      <quotation-info/>
    </div>

    <div class="ui segment">
      <quotation-items/>
    </div>

    <div class="ui segment">
      <quotation-price-list/>
    </div>
  </div>


  <modal-add-to-items/>

  <modal-create-new-price-list-item/>
  <modal-edit-price-list-item/>

</template>

<script setup>
import {onMounted} from "vue";
import {useRoute} from "vue-router";
import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import QuotationPriceList from "@/components/quotationUpdate/quuotation-price-list.vue"
import QuotationInfo from "@/components/quotationUpdate/quuotation-info.vue"
import QuotationItems from "@/components/quotationUpdate/quuotation-items.vue"

import ModalAddToItems from "@/components/quotationUpdate/modal-add-to-items.vue";
import ModalCreateNewPriceListItem from "@/components/quotationUpdate/modal-create-price-list-item.vue"
import ModalEditPriceListItem from "@/components/quotationUpdate/modal-edit-price-list-item.vue";

import ErrorComponent from "@/components/ErrorComponent.vue";

const store_quotation = useApiQuotationStore();

onMounted(() => {
  const route = useRoute();
  store_quotation.fetchQuotation(route.params.quotation_id)
})

</script>

<style scoped>

</style>