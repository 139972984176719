<template>


  <MainMenu v-if="isAuth"/>

  <router-view>

  </router-view>

</template>

<script>


import MainMenu from "@/components/MainMenu";
import {mapState} from 'vuex'

export default {
  name: 'App',
  components: {
    MainMenu
  },
  computed: mapState({
        isAuth: state => state.app.isAuthenticated
      }
  ),
  created(){
    const params = new URLSearchParams(window.location.search)
    this.$store.dispatch('app/auth', params.get('data'))


  }

}
</script>

<style>
</style>
