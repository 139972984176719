<template>
  <div class="ui segment">
    <h3>Költségek</h3>
    <prices-table />
  </div>
</template>

<script>
import PricesTable from "@/components/quotation/create/pricesTable";

export default {
  name: "PricesSegment",
  props: [],
  components: {
    PricesTable
  },
  computed: {}
}
</script>

<style scoped>

</style>
