import axios from "axios";

import wsseInterceptor from "@/_helper/wsseIntercentor";

const state = () => ({

})

const getters = {}

const mutations = {}

const actions = {

    async createAddress({rootState}, data) {
        wsseInterceptor(rootState.app)
        const url_address = rootState.app.hostServer + '/api/addresses'

        return await axios.post(url_address, data)
            .then((response) => {
                return response.data
            })
    },

    async updateAddress({rootState}, address) {
        wsseInterceptor(rootState.app)
        const url_address = rootState.app.hostServer + '/api/addresses/' + address.id

        const send_address_data = {...address}
        delete send_address_data.id
        return await axios.patch(url_address, send_address_data)
            .then((response) => {
                return response.data
            })
    },




}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
