<template>
  <table class="ui celled table">
    <thead>
    <tr>
      <th>Megnevezés yyy</th>
      <th>Mennyiség</th>
      <th class="center aligned">Egyszeri díj</th>
      <th class="center aligned">Havidíj</th>
      <th class="center aligned">Egyszeri díj</th>
      <th class="center aligned">Havidíj</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(cost, index) in costs" :key="index">
      <td data-label="Megnevezés">{{ cost.name }}</td>
      <td data-label="Mennyiség">{{ cost.quantity }}</td>
      <td data-label="Egyszeri díj" class="right aligned">{{ cost.unitPriceOneTime }} Ft</td>
      <td data-label="Havidíj" class="right aligned">{{ cost.unitPriceMonthly }} Ft/hó</td>

      <td data-label="Egyszeri díj" class="right aligned">{{ cost.priceOneTime }} Ft</td>
      <td data-label="Havidíj" class="right aligned">{{ cost.priceMonthly }} Ft/hó</td>
    </tr>
    </tbody>
    <tfoot class="full-width">
    <tr>
      <th class="sum_row" colspan="4">Összesen</th>
      <th class="right aligned sum_row">{{ sum.priceOneTime }} Ft</th>
      <th class="right aligned sum_row">{{ sum.priceMonthly }} Ft/hó</th>
    </tr>
    </tfoot>
  </table>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'PricesTable',
  computed: {
    ...mapState({
      costs: state => state.createQuotation.calculation.result.costs,
      sum: state => state.createQuotation.calculation.result.sum
    }),

  }

}
</script>

<style scoped>
.sum_row {
  color: red;
  font-weight: bold !important;

}

</style>
