<template>
  <vee-form class="ui form"
            @submit="onIdentitySubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{errors}">


    <div class="ui three fields">
      <div class="field" :class="{error: `name` in errors}">
        <label>Név</label>
        <div class="ui input">
          <vee-field type="text" name="name"/>
        </div>
        <error-message name="name" class="error-message"/>
      </div>

      <div class="field" :class="{error: 'city' in errors}">
        <label>Település</label>
        <div class="ui input">
          <vee-field type="text" name="city"/>
        </div>
        <error-message name="city" class="error-message"/>
      </div>

      <div class="field" :class="{error: `street` in errors}">
        <label>Utca, házszám</label>
        <div class="ui input">
          <vee-field type="text" name="street"/>
        </div>
        <error-message name="street" class="error-message"/>
      </div>

    </div>

    <div>
      <div class="actions">
        <button class="ui black deny button"
                @click="$emit('close')">
          Mégsem
        </button>

        <button type="submit"
                class="ui positive right labeled icon button">
          Mentés
          <i class="checkmark icon"></i>
        </button>

      </div>
    </div>


  </vee-form>

</template>

<script>
import {Form, Field} from 'vee-validate';
import * as yup from "yup";

export default {
  name: "identity-edit-form",

  components: {
    VeeForm: Form,
    VeeField: Field
  },

  data() {
    return {
      schema: yup.object().shape(
          {
            name: yup.string().required('A telepítés helyét el kell nevezni!'),
            city: yup.string().required('A település nevét meg kell adni!'),
            street: yup.string().required('Megadása kötelező!'),
          }
      ),
      initialValues: {
        name: this.$store.state.updateContract.editedSite.nameAtContract,
        city: this.$store.state.updateContract.editedSite.address.city,
        street: this.$store.state.updateContract.editedSite.address.street
      },
    }

  },

  methods: {
    onIdentitySubmit(values) {

      const send_data_site = {
        id: this.$store.state.updateContract.editedSite.id,
        nameAtContract: values.name,
        address: {
          id: this.$store.state.updateContract.editedSite.address.id,
          city: values.city,
          street: values.street
        }
      }

      console.log('SEND_DATA:', send_data_site)


      this.$store.dispatch('updateContract/updateItem', send_data_site)

      this.$emit('close')

    }
  }
}
</script>

<style scoped>

</style>
